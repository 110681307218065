import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { NotificationService } from '../components/notifications/notification.service';
import { NotificationType } from '../model/enum/notification-type.enum';
import { Profile } from '../model/user/profile.model';
import { AttendeesService } from './attendees.service';
import { EmailService } from './email.service';

@Injectable({
  providedIn: 'root'
})
export class ActionsService {

  constructor(
    private emailService: EmailService,
    private attendeesService: AttendeesService,
    private notificationService: NotificationService
  ) { }

  sendVerificationEmail(attendee: Profile) {
    return this.emailService
      .sendVerificationEmail(attendee.user.id)
      .pipe(
        tap({
          next: _ => {
            this.notificationService.notify('Verification Email', 'The email has been successfully sent.', NotificationType.Success);
          }
        })
      );
  }

  sendMagicLink(attendee: Profile) {
    return this.emailService
      .genMagicLink(attendee.user.id)
      .pipe(
        tap({
          next: _ => {
            this.notificationService.notify('Magic Link', 'The email has been successfully sent.', NotificationType.Success);
          }
        })
      );
  }

  sendUpdatePasswordEmail(attendee: Profile) {
    return this.emailService
      .sendUpdatePasswordEmail(attendee.user.id)
      .pipe(
        tap({
          next: _ => {
            this.notificationService.notify('Verification Email', 'The email has been successfully sent.', NotificationType.Success);
          }
        })
      );
  }

  sendConfirmationEmail(attendee: Profile) {
    return this.emailService
      .sendConfirmationEmail(attendee.id)
      .pipe(
        tap({
          next: _ => {
            this.notificationService.notify('Verification Email', 'The email has been successfully sent.', NotificationType.Success);
          }
        })
      );
  }

  remove(attendee: Profile) {
    return this.attendeesService
      .removeAttendee(attendee.id)
      .pipe(
        tap({
          next: _ => {
            this.notificationService.notify('Attendee', 'This attendee is no longuer registred in this event.', NotificationType.Success);
          }
        })
      );
  }

  checkIn(attendee: Profile, checkPoint?: string) {
    return this.attendeesService
      .setEventPresence(attendee.id, checkPoint)
      .pipe(tap({
        next: _ => {
          this.notificationService.notify('Check-In Attendee', 'This attendee has been checked-in!.', NotificationType.Success);
        }
      }));
  }
}
