<div class="px-2 md:px-7 py-7">
  <div class="mx-auto max-w-5xl">
    <nav class="flex mb-4" aria-label="Breadcrumb">
      <ol role="list" class="flex items-center space-x-4">
        <li>
          <div class="flex items-center justify-start">
            <a [routerLink]="['/event-editor', event.id, 'menu']" class="text-sm font-medium text-gray-500 hover:text-gray-700">Event Editor</a>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor"
              aria-hidden="true">
              <path fill-rule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clip-rule="evenodd" />
            </svg>
            <a [routerLink]="['/event-editor', event.id, 'details']" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700" aria-current="page">Streamline Event Details</a>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor"
              aria-hidden="true">
              <path fill-rule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clip-rule="evenodd" />
            </svg>
            <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700" aria-current="page">Event Sponsors</a>
          </div>
        </li>
      </ol>
    </nav>
  </div>
  <div class="space-y-12 mt-7">
    <div class="mx-auto max-w-4xl lg:text-center">
      <h2 class="text-base font-semibold leading-7 text-main">Event Sponsors</h2>
      <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl line-clamp-2 text-ellipsis">{{
        event.name }}
      </p>
      <p class="mt-6 text-lg leading-8 text-gray-600">Define and manage event sponsors effectively with Sponsors.
        Showcase their contributions and enhance event visibility through strategic partnerships.</p>
    </div>
  </div>
  <div class="mx-auto max-w-5xl mt-24">
    <div class="border-b border-gray-900/10 pb-12">
      <div class="grid max-w-full grid-cols-1 gap-x-6 gap-y-8">
        <div class="md:col-span-2">
          <div class="flex flex-col gap-y-3">
            <div class="flex justify-end">
              <button (click)="openModal()" type="button"
                class="inline-flex items-center gap-x-1.5 rounded-md bg-main px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-main focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
                <svg class="-ml-0.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                  stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                New Sponsor
              </button>
            </div>
            <div class="flex justify-center items-start">
              <span *ngIf="saving">
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-main" xmlns="http://www.w3.org/2000/svg" fill="none"
                  viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                  </path>
                </svg>
              </span>
              <div *ngIf="!saving && event.sponsors.length > 0">
                <ul role="list"
                  class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2">
                  <li *ngFor="let sponsor of event.sponsors">
                    <ng-container *ngIf="sponsor">
                      <img *ngIf="sponsor?.logo?.name" class="aspect-[3/2] w-full rounded-2xl object-cover"
                        [src]="sponsor.logo.name | image | async" alt="">
                      <svg *ngIf="!sponsor.logo" class="aspect-[3/2] w-full rounded-2xl object-cover text-slate-400"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                      </svg>
                      <h3 class="mt-6 text-lg font-semibold leading-8 text-gray-900">{{ sponsor.name }}</h3>
                      <p class="text-base leading-7 text-gray-600 line-clamp-1">{{ sponsor.description }}</p>
                      <ul role="list" class="mt-6 flex gap-x-6">
                        <li>
                          <a (click)="selectedSponsor=sponsor;confirmDelete()" class="text-gray-400 hover:text-gray-500">
                            <span class="sr-only">Remove</span>
                            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none"
                              viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a (click)="selectedSponsor=sponsor;openUpdateSponsor()"
                            class="text-gray-400 hover:text-gray-500">
                            <span class="sr-only">Edit</span>
                            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none"
                              viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a [href]="sponsor.link" target="_blank" class="text-gray-400 hover:text-gray-500">
                            <span class="sr-only">Link</span>
                            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none"
                              viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round"
                                d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <label [for]="sponsor.id" class="text-gray-400 hover:text-gray-500">
                            <span class="sr-only">Logo</span>
                            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none"
                              viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round"
                                d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                            </svg>
                            <input [id]="sponsor.id" name="logo" type="file" class="sr-only"
                              (change)="onSelectLogo($event, sponsor)">
                          </label>
                        </li>
                      </ul>
                    </ng-container>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="mt-12">
      <div class="my-7">
        <p class="leading-7 text-slate-700 text-base">
          Easily proceed to <span class="font-bold">Event Brochures</span> by clicking "Manage Brochures." This button will
          guide you through updating brochures of your event.
        </p>
      </div>
      <div class="flex">
        <div (click)="trackUpdate('Brochures')" [routerLink]="['/event-editor', event.id, 'event-brochures']" class="flex flex-col borded mt-4 md:w-1/2">
          <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
            <svg class="h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z"
                clip-rule="evenodd" />
            </svg>
            Event Brochures
          </dt>
          <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
            <p class="flex-auto">Upload event brochures and related documents easily with Brochures. Provide attendees
              with essential information and resources conveniently.</p>
            <p class="mt-6 md:text-end">
              <button type="button"
                class="rounded-md inline-flex items-center gap-x-1.5 bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Manage Brochures
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="-mr-0.5 h-5 w-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                </svg>
              </button>
            </p>
          </dd>
        </div>
      </div>
    </div>
  </div>
</div>


<div @opacity *ngIf="isModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div @opacityTranslateY *ngIf="isModal"
        class="relative transform overflow-hidden rounded-lg bg-white px-7 pb-5 pt-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
        <div>
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="space-y-12">
              <div class>
                <h2 class="text-center text-base font-semibold leading-7 text-gray-900">Create a sponsor</h2>
                <p class="text-center mt-1 text-sm leading-6 text-gray-600"></p>
                <div class="mt-7 grid grid-cols-1 gap-x-6 gap-y-7">
                  <div class="col-span-full">
                    <label for="name" class="block text-sm font-medium leading-6 text-gray-900">
                      Sponsor name
                    </label>
                    <div class="mt-2">
                      <div
                        class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main">
                        <input type="text" name="name" formControlName="name" id="name" autocomplete="name"
                          class="block flex-1 border-0 bg-transparent text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="Enter the sponsor name">
                      </div>
                    </div>
                  </div>

                  <div class="col-span-full">
                    <label for="link" class="block text-sm font-medium leading-6 text-gray-900">Sponsor Link</label>
                    <div class="mt-2">
                      <div
                        class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main w-full">
                        <span class="flex select-none items-center pl-3 text-gray-500 sm:text-sm">https://</span>
                        <input type="text" name="link" id="link" formControlName="link"
                          class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6">
                      </div>
                    </div>
                  </div>

                  <div class="col-span-full">
                    <label for="description" class="block text-sm font-medium leading-6 text-gray-900">
                      Description
                    </label>
                    <div class="mt-2">
                      <div
                        class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main">
                        <textarea type="text" name="description" formControlName="description" id="description"
                          autocomplete="description"
                          class="block flex-1 border-0 bg-transparent text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder>
                        </textarea>
                      </div>
                    </div>
                  </div>

                  <div class="col-span-full">
                    <div class="sm:flex sm:flex-row-reverse">
                      <button type="submit" [disabled]="!form.valid || saving"
                        class="disabled:bg-opacity-25 inline-flex items-center w-full justify-center rounded-md bg-main px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-main/50 sm:ml-3 sm:w-auto">
                        <svg *ngIf="saving" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                          </circle>
                          <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                          </path>
                        </svg>
                        Save
                      </button>
                      <button (click)="closeModal()" type="button"
                        class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<div @opacity *ngIf='confirmationModal' class="relative z-[60]" aria-labelledby="modal-title" role="dialog"
  aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-[60] overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">

      <div @opacityTranslateY *ngIf='confirmationModal'
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
        <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
          <button type="button" (click)="cancelDelete()"
            class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <span class="sr-only">Close</span>
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
              aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
              aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Delete sponsor</h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">Are you sure you want to delete this sponsor? This action cannot be
                undone.</p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <span *ngIf="saving">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-main" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
              </path>
            </svg>
          </span>
          <button *ngIf="!saving" type="button" (click)="deleteSponsor()"
            class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Delete</button>
          <button *ngIf="!saving" type="button" (click)="cancelDelete()"
            class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
