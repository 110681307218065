<a [routerLink]="'/event-editor/' + event.id" (click)="onSelect()"
  class="hover:bg-gray-100 p-2 rounded-md flex justify-between items-center">
  <div class="flex">
    <div class="mr-4 flex-shrink-0 self-end">
      <div *ngIf="!isLogoExists()"
        class="w-10 h-10 md:w-14 md:h-14 rounded-md shadow-sm flex items-center justify-center border border-slate-100 bg-slate-100 p-1">
        <svg class="inline-block h-10 w-10 rounded-md fill-slate-500" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24" fill="currentColor">
          <path
            d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
          <path fill-rule="evenodd"
            d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z"
            clip-rule="evenodd" />
        </svg>
      </div>
      <img *ngIf="isLogoExists()" class="inline-block w-10 h-10 md:h-14 md:w-14 rounded-md"
        [src]="event.theme.logo.name | image | async" alt="">
    </div>
    <div>
      <h4 class="text-sm md:text-lg font-medium font-title text-slate-700 line-clamp-1">{{event.name }}</h4>
      <p class="mt-1 text-slate-500 text-sm line-clamp-1">{{ event.community.name }}</p>
    </div>
  </div>
  <div class="hidden md:block">
    <svg class="w-5 h-5 stroke-slate-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
      stroke-width="1.5" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
    </svg>
  </div>
</a>
