<div class="px-11 pt-11 pb-20">
  <div class="">
    <div class="min-w-0 flex-1">
      <nav class="flex mb-4" aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-4">
          <li>
            <div class="flex items-center justify-start">
              <a routerLink="../" class="text-sm font-medium text-gray-500 hover:text-gray-700">Notifications</a>
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor"
                aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clip-rule="evenodd" />
              </svg>
              <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700" aria-current="page">Boost
                Engagement</a>
            </div>
          </li>
        </ol>
      </nav>
      <h2 class="mt-2 text-2xl font-bold leading-9 text-slate-700 sm:text-3xl sm:tracking-tight">Boost Engagement</h2>
      <div class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
        <div class="mt-2 flex items-center text-sm text-gray-500">
          Sending personalized emails will make each participant feel valued and more connected to the event.
        </div>
      </div>
    </div>
  </div>

  <div class="mt-7 px-10">
    <dl class="space-y-3 divide-y divide-gray-900/10">
      <div class="pt-6">
        <dt>
          <button type="button" (click)="toggleOpen()"
            class="flex w-full items-start justify-between text-left text-gray-900" aria-controls="faq-0"
            aria-expanded="false">
            <span class="text-base font-semibold leading-7">Using special variables in your email template</span>
            <span class="ml-6 flex h-7 items-center">
              <svg *ngIf="!isOpen" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg>
              <svg *ngIf="isOpen" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
              </svg>
            </span>
          </button>
        </dt>
        <dd *ngIf="isOpen" class="mt-2 pr-12" id="faq-0">
          <p class="text-base leading-7 text-gray-600">
            To personalize your email templates, you can use the following special variables. Simply include them in
            your email content where you want the corresponding information to appear:
          </p>
          <ul class="list-disc list-inside mt-3.5 text-slate-700 font-normal">
            <li>
              <span>[attendeeSalutation]</span>: Inserts the attendee's salutation.
            </li>
            <li>
              <span>[attendeeName]</span>: Inserts the attendee's name.
            </li>
            <li>
              <span>[eventName]</span>: Inserts the name of the event.
            </li>
            <li>
              <span>[eventLocation]</span>: Inserts the location of the event.
            </li>
            <li>
              <span>[eventDatesWithTime]</span>: Inserts the date and the time following this format: 27-30 Avril, 2024, 09:00 - 19:00 UTC+1
            </li>
            <li>
              <span>[eventDates]</span>: Inserts the date following this format: 27-30 Avril, 2024
            </li>
            <li>
              <span>[eventTime]</span>: Inserts the time following this format: 9:00 - 19:00 UTC+1
            </li>
            <li>
              <span>[authLink]</span>: Inserts the authentication link for the user to connect to his account in one
              click.
            </li>
          </ul>
          <p class="mt-4">
            Example:
          </p>
          <pre class="mt-2.5 text-slate-700 font-text">
              Hello [attendeeSalutation] [attendeeName],

              We are excited to invite you to [eventName]!

              Location: [eventLocation]
              Dates: [eventDatesWithTime]

              We look forward to seeing you there!

              Best regards,

              You can use this link to login to you account: [authLink]
            </pre>
        </dd>
      </div>
    </dl>
    <dl class="space-y-1 divide-y divide-gray-900/10">
      <div class="pt-6">
        <dt>
          <button type="button" (click)="toggleOpen2()"
            class="flex w-full items-start justify-between text-left text-gray-900" aria-controls="faq-0"
            aria-expanded="false">
            <span class="text-base font-semibold leading-7">Default text content in your email template</span>
            <span class="ml-6 flex h-7 items-center">
              <svg *ngIf="!isOpen2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg>
              <svg *ngIf="isOpen2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
              </svg>
            </span>
          </button>
        </dt>
        <dd *ngIf="isOpen2" class="mt-2 pr-12" id="faq-0">
          <p class="text-base leading-7 text-gray-600">
            Please note that some default text content will be automatically added to your email template. <br>
            This content is designed to ensure consistency and provide essential information to your recipients. It may
            include authentication credentials and other important details.
          </p>
          <p class="mt-4">
            Example of default content:
          </p>
          <ul class="list-disc list-inside mt-3.5 text-slate-700 font-normal">
            <li>
              <span>Greeting</span>: Hello [attendeeSalutation] [attendeeName],
            </li>
            <li>
              <span>Introduction</span>: We are excited to invite you to [eventName]!
            </li>
            <li>
              <span>Event Details</span>: Location: [eventLocation] Start Time: [eventDatesWithTime]
            </li>
            <li>
              <span>Authentication Credentials:</span>: Information required for accessing event-related resources
            </li>
            <li>
              <span>Additional Information</span>: Any other relevant details necessary for the event.
            </li>
            <li>
              <span>Closing</span>: We look forward to seeing you there! Best regards, [Your Event Name]
            </li>
          </ul>
          <p class="mt-4 text-base leading-7 text-gray-600">
            You can customize the remaining parts of the template to suit your specific needs. However, please keep the
            default content intact to maintain a consistent structure and ensure that all necessary information is
            included.
          </p>
        </dd>
      </div>
    </dl>
  </div>

  <div class="mt-11 px-10">
    <form (ngSubmit)="onSubmit()" #form="ngForm">
      <div class="mt-10 grid grid-cols-4 gap-x-6 gap-y-10">
        <div class="col-span-full">
          <label for="subject" class="block text-sm font-medium leading-6 text-slate-700">Subject *</label>
          <div class="mt-2">
            <input type="text" name="subject" id="subject" autocomplete="off" ngModel #subject="ngModel"
              class="block w-full rounded-md border-0 py-1.5 text-slate-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6"
              required>
          </div>
          <div class="mt-1.5" *ngIf="!subject.valid && subject.touched">
            <p class="text-xs text-slate-600">Subject is required</p>
          </div>
        </div>

        <div class="col-span-full">
          <label for="content" class="block text-sm font-medium leading-6 text-slate-700">Content *</label>
          <div class="mt-2">
            <rich-text-input [defaultTemplate]="''" (onInputChange)="onEditorChange($event)"></rich-text-input>
          </div>
        </div>
      </div>
      <div class="flex justify-center w-full mt-12">
        <button [disabled]="!form.valid || sending || htmlContent.length < 5" type="submit"
          class="disabled:cursor-none disabled:bg-main/65 w-2/3 rounded-md bg-main px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-main/60 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
          Send
        </button>
      </div>
    </form>
  </div>
</div>
