import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { NotificationService } from '../components/notifications/notification.service';
import { NotificationType } from '../model/enum/notification-type.enum';
import { PaymentGateway, WaveConfig, WaveConfigRequest } from '../model/event/wave-config.request';
import { AppSettingService } from './app-setting.service';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentGatewayConfigService {

  constructor(
    private appSettings: AppSettingService,
    private httpClient: HttpClient,
    private notificationService: NotificationService,
    private eventService: EventService
  ) { }


  public getWaveConfig(): Observable<WaveConfig> {
    const url: string = `${this.appSettings.settings.url}/configure-payment-gateway/wave/${this.eventService.selectedEvent.value.id}`;
    const api = this.httpClient.get<WaveConfig>(url);
    return api.pipe();
  }

  public configureWave(request: WaveConfigRequest) {
    const url: string = `${this.appSettings.settings.url}/configure-payment-gateway/wave`;
    const api = this.httpClient.patch(url, request);

    return api.pipe(
      catchError(error => {
        this.notificationService.notify('Payment Gateway not Saved', `An issue is preventing this action.`, NotificationType.Error);
        return error;
      })
    );
  }

  public getGatewayOptions(): PaymentGateway[] {
    const gateways: PaymentGateway[] = [];
    gateways.push({
      label: 'Wave Money',
      logo: 'assets/logo/wave.png'
    });
    return gateways;
  }
}
