<div class="mx-auto max-w-6xl">
  <div class="mx-auto">
    <div class="flex justify-between items-center">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Print Badges
        @if(selectedCheckPoint){
        : {{ selectedCheckPoint }}
        }
      </h2>
      <div class="relative inline-block text-left">
        <div>
          <button type="button" (click)="toggleMenu()"
            class="w-56 inline-flex justify-center gap-x-1.5 rounded-md bg-main px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-main hover:bg-main/95"
            id="menu-button" aria-expanded="true" aria-haspopup="true">
            @if(selectedCheckPoint){
            {{ selectedCheckPoint }}
            } @else {
            Select Check Point
            }
            <svg class="-mr-1 h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clip-rule="evenodd" />
            </svg>
          </button>
        </div>

        <div @opacityScale *ngIf='isMenu'
          class="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
          <div class="py-1" role="none">
            @for (checkpoint of checkpoints; track $index) {
            <a (click)="onSelectCheckPoint(checkpoint)"
              class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 block px-4 py-2 text-sm" role="menuitem"
              tabindex="-1" id="menu-item-0">
              {{ checkpoint }}
            </a>
            }
          </div>
        </div>
      </div>
      <div *ngIf='isMenu' (click)="toggleMenu()" class="fixed inset-0"></div>
    </div>
    <p class="mt-6 text-lg leading-8 text-gray-600">Streamline the printing of attendee badges, ensuring
      high-quality, accurate, and ready-to-use badges for seamless event check-ins.</p>
  </div>

  <div class="h-[700px] overflow-y-scroll relative mt-5">
    <div class="sticky top-0 z-10 flex h-16 shrink-0 items-center border-b border-white/45 bg-gray-200 px-4 rounded-md">
      <div class="flex flex-1 gap-x-4 self-stretch">
        <form class="flex flex-1">
          <label for="search-field" class="sr-only">Search</label>
          <div class="relative w-full">
            <svg class="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-slate-700" viewBox="0 0 20 20"
              fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                clip-rule="evenodd" />
            </svg>
            <input id="search-field" #searchInput (keyup)="search(getValue($event))" [autofocus]="true"
              class="block h-full w-full border-0 bg-transparent py-0 pl-8 pr-0 text-slate-700 focus:ring-0 sm:text-sm"
              placeholder="Search By ID, email, First Name, Last Name, Group, External Reference" type="search"
              name="search">
          </div>
        </form>
      </div>
    </div>
    <p class="text-xs text-slate-500 pl-1.5 pt-1.5">
      You need to write at lest 3 characters.
    </p>
    <main class="mt-10">
      <header class="flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 lg:px-8">
        <h1 class="text-base font-semibold leading-7 text-slate-700">
          @if(!searching && (result$ | async)?.length > 0){
          Attendees
          }@else if(searching) {
          <div class="text-slate-700 inline-flex items-center">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
              </path>
            </svg>
            Searching Attendees...
          </div>
          }@else {

          }
        </h1>
      </header>
      <div class="grid grid-cols-2">
        <div class="pl-5 px-10">
          @if ((result$ | async)?.length == 0 ) {
          <div class="text-center">
            <svg class="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
            </svg>
            <h3 class="mt-2 text-sm font-semibold text-gray-900">No Results</h3>
            <p class="mt-1 text-sm text-gray-500">We did not found any participants, Please update your keyword!</p>
          </div>
          }@else {
          <ul role="list"
            class="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-md">
            @for ( attendee of result$ | async; track $index) {
            <li (click)="onSelect(attendee)"
              class="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
              <div class="flex min-w-0 gap-x-4">
                @if (attendee.user?.profilePhoto?.name) {
                <img class="h-12 w-12 flex-none rounded-full bg-gray-50"
                  [src]="attendee.user.profilePhoto?.name | image | async" alt="">
                } @else {
                <svg class="h-12 w-12 flex-none rounded-full fill-slate-300" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                    clip-rule="evenodd" />
                </svg>
                }
                <div class="min-w-0 flex-auto">
                  <p class="text-sm font-semibold leading-6 text-gray-900">
                    <a>
                      <span class="absolute inset-x-0 -top-px bottom-0"></span>
                      {{ attendee.salutation }} {{ attendee.firstName }} {{ attendee.lastName }}
                    </a>
                  </p>
                  <p class="mt-1 flex text-xs leading-5 text-gray-500">
                    <a href="mailto:{{ attendee.email }}" class="relative truncate hover:underline">{{ attendee.email
                      }}</a>
                  </p>
                  <p class="mt-1 text-xs leading-5 text-slate-500 flex items-center justify-start gap-x-1">
                    <span
                      class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{
                      attendee.id }}</span>
                    @if (attendee.externalReference) {
                    <span
                      class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                      {{ attendee.externalReference }}
                    </span>
                    }
                  </p>
                  @if (attendee.tags && attendee.tags.length > 0) {
                  <p class="mt-1 text-xs leading-5">
                    @for ( tag of attendee.tags ; track $index ) {
                    <span
                      class="mr-1 inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">{{
                      tag }}</span>
                    }
                  </p>
                  }
                  @if (attendee.groups && attendee.groups.length > 0) {
                  <p class="mt-1 text-xs leading-5">
                    @for ( group of attendee.groups ; track $index ) {
                    <span
                      class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 mr-1.5">{{
                      group }}</span>
                    }
                  </p>
                  }
                </div>
              </div>
              <div class="flex shrink-0 items-center gap-x-4">
                <div class="hidden sm:flex sm:flex-col sm:items-end">
                  <p class="text-sm leading-6 text-gray-900">{{ attendee.occupation }}</p>
                  <p class="mt-1 text-xs leading-5 text-gray-500">{{ attendee.company }}</p>
                </div>
                <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clip-rule="evenodd" />
                </svg>
              </div>
            </li>
            }
          </ul>
          }
        </div>
        <div class="">
          <div class="sticky top-16" *ngIf="selectedProfile">
            <div class="flex flex-col items-start justify-between gap-y-2">
              <div class="min-w-0 flex-1">
                <nav class="flex" aria-label="Breadcrumb">
                  <ol role="list" class="flex items-center space-x-4">
                    <li>
                      <div class="flex">
                        <a href="#" class="text-sm font-medium text-gray-500 hover:text-gray-700">{{ occupation }}</a>
                      </div>
                    </li>
                  </ol>
                </nav>
                <h2 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                  {{
                  salutation }} {{ firstName }} {{ lastName }}</h2>
                <div class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                  <div *ngIf="company" class="mt-2 flex items-center text-sm text-gray-500">
                    <svg class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor"
                      aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M6 3.75A2.75 2.75 0 018.75 1h2.5A2.75 2.75 0 0114 3.75v.443c.572.055 1.14.122 1.706.2C17.053 4.582 18 5.75 18 7.07v3.469c0 1.126-.694 2.191-1.83 2.54-1.952.599-4.024.921-6.17.921s-4.219-.322-6.17-.921C2.694 12.73 2 11.665 2 10.539V7.07c0-1.321.947-2.489 2.294-2.676A41.047 41.047 0 016 4.193V3.75zm6.5 0v.325a41.622 41.622 0 00-5 0V3.75c0-.69.56-1.25 1.25-1.25h2.5c.69 0 1.25.56 1.25 1.25zM10 10a1 1 0 00-1 1v.01a1 1 0 001 1h.01a1 1 0 001-1V11a1 1 0 00-1-1H10z"
                        clip-rule="evenodd" />
                      <path
                        d="M3 15.055v-.684c.126.053.255.1.39.142 2.092.642 4.313.987 6.61.987 2.297 0 4.518-.345 6.61-.987.135-.041.264-.089.39-.142v.684c0 1.347-.985 2.53-2.363 2.686a41.454 41.454 0 01-9.274 0C3.985 17.585 3 16.402 3 15.055z" />
                    </svg>
                    {{ company }}
                  </div>
                  <div *ngIf="selectedProfile.country?.name" class="mt-2 flex items-center text-sm text-gray-500">
                    <svg class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor"
                      aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
                        clip-rule="evenodd" />
                    </svg>
                    {{ selectedProfile.country?.name }}
                  </div>
                  <div class="mt-2 flex items-center text-sm text-gray-500">
                    @if(!selectedProfile.presentAtEventVenue){
                    <svg class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                      fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
                    </svg>
                    <span
                      class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">Did
                      not checkin</span>
                    } @else {
                    <svg class="mr-1.5 h-5 w-5 flex-shrink-0 fill-green-600" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24" fill="currentColor">
                      <path fill-rule="evenodd"
                        d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                        clip-rule="evenodd" />
                    </svg>
                    <span
                      class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                      Checked-In<span *ngIf="checkInDate">: {{ checkInDate | date: 'MMMM d, y h:mm a' }}</span>
                    </span>
                    }
                  </div>
                </div>
              </div>
              <div class="mt-5 flex">
                <span class="hidden sm:block">
                  <button (click)="toggleModal()" type="button"
                    class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    <svg class="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor"
                      aria-hidden="true">
                      <path
                        d="M2.695 14.763l-1.262 3.154a.5.5 0 00.65.65l3.155-1.262a4 4 0 001.343-.885L17.5 5.5a2.121 2.121 0 00-3-3L3.58 13.42a4 4 0 00-.885 1.343z" />
                    </svg>
                    Edit
                  </button>
                </span>

                <span class="sm:ml-3">
                  <button (click)="print()" type="button"
                    class="inline-flex items-center rounded-md bg-main px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
                    <svg class="-ml-0.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                      viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z" />
                    </svg>
                    Print
                  </button>
                </span>
              </div>
            </div>
            <div class="rounded-md flex flex-col items-center justify-start">
              <div #badge class="block flex-col justify-center items-center bg-transparent w-[10.4cm] h-[14.9]">
                <div class="w-[10.4cm] h-[4cm]">

                </div>

                <div class="w-[10.4cm] h-[9.9cm] flex flex-col justify-evenly items-center">
                  <div class="text-center">
                    <h3 class="text-2xl font-title font-bold text-black">{{ salutation }} {{ firstName }} {{ lastName }}
                    </h3>
                    <p class="text-base font-semibold text-black font-text">{{ occupation }}</p>
                    <p class="text-base font-text font-bold text-black uppercase">{{ company }}</p>
                    <p class="text-base font-text font-bold text-black mt-1.5 uppercase">{{ country }}</p>
                  </div>
                  <div>
                    <div class="flex justify-center mt-3">
                      <img [src]="qrCodeUrl" width="90" height="90" alt="qrcode" alt="">
                    </div>
                    <div class="text-center">
                      <span class="text-[7px] text-black font-text">{{ selectedProfile.id }}</span>
                    </div>
                  </div>
                </div>


                <div class="w-[10.4cm] h-[1cm]">

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>

<div *ngIf="isEditModal" class="relative z-20" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div @opacity *ngIf='isEditModal' class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    aria-hidden="true"></div>

  <div class="fixed inset-0 z-20 w-screen overflow-y-auto">
    <div *ngIf='isEditModal' (click)="toggleModal()" class="fixed inset-0"></div>
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div @opacityTranslateY *ngIf='isEditModal'
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-[777px] sm:p-6">
        <div>
          <form>
            <div>
              <h2 class="text-base font-semibold leading-7 text-gray-900">Badge Information</h2>
              <p class="mt-1 text-sm leading-6 text-gray-600">Updating badge information won't update this attendees
                details!</p>

              <div class="mt-10 grid grid-cols-2 gap-x-6 gap-y-8">
                <div class="sm:col-span-2">
                  <label for="salutation" class="block text-sm font-medium leading-6 text-gray-900">Salutation</label>
                  <div class="mt-2">
                    <input [(ngModel)]="salutation" type="text" name="salutation" id="salutation"
                      autocomplete="given-name"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  </div>
                </div>

                <div class="md:col-span-1 col-span-2">
                  <label for="firstName" class="block text-sm font-medium leading-6 text-gray-900">First Name</label>
                  <div class="mt-2">
                    <input [(ngModel)]="firstName" type="text" name="firstName" id="firstName"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  </div>
                </div>

                <div class="md:col-span-1 col-span-2">
                  <label for="lastName" class="block text-sm font-medium leading-6 text-gray-900">Last Name</label>
                  <div class="mt-2">
                    <input [(ngModel)]="lastName" type="text" name="lastName" id="lastName"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  </div>
                </div>

                <div class="md:col-span-1 col-span-2">
                  <label for="occupation" class="block text-sm font-medium leading-6 text-gray-900">Occupation</label>
                  <div class="mt-2">
                    <input [(ngModel)]="occupation" type="text" name="occupation" id="occupation"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  </div>
                </div>

                <div class="md:col-span-1 col-span-2">
                  <label for="company" class="block text-sm font-medium leading-6 text-gray-900">Company</label>
                  <div class="mt-2">
                    <input [(ngModel)]="company" type="text" name="company" id="company"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  </div>
                </div>

                <div class="col-span-2">
                  <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Coutry</label>
                  <div class="mt-2">
                    <input [(ngModel)]="country" type="text" name="country" id="country"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-6 flex items-center justify-end gap-x-6">
              <button (click)="cancel()" type="button"
                class="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
              <button (click)="save()" type="button"
                class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isOptionModal" class="relative z-20" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div @opacity *ngIf='isOptionModal' class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    aria-hidden="true"></div>

  <div class="fixed inset-0 z-20 w-screen overflow-y-auto">
    <div *ngIf='isOptionModal' class="fixed inset-0"></div>
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div @opacityTranslateY *ngIf='isOptionModal'
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-[777px] sm:p-6">
        <div class="absolute top-3 right-3">
          <button (click)="toggleOptionModal()"
            class="w-7 h-7 bg-gray-100 rounded-full flex items-center justify-center hover:cursor-pointer hover:bg-gray-300 ">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="size-5 text-slate-500">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="mx-auto flex-auto text-left">
          <h2 class="text-3xl font-bold tracking-tight text-gray-700 sm:text-4xl">Participant Check-In</h2>
          <p class="mt-6 text-lg leading-8 text-gray-500">Easily check in participants to track event attendance and
            manage entry.</p>
        </div>
        <div>
          <ul role="list" class="mx-auto flex justify-center items-end gap-x-8 mt-10">
            <li (click)="checkIn()"
              class="border border-slate-200 rounded-md py-7 px-5 hover:bg-slate-100 hover:cursor-pointer">
              <svg class="mx-auto h-40 w-40 fill-slate-500 stroke-slate-600" version="1.1" id="Capa_1"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 572.475 572.475" xml:space="preserve" stroke="#77767b">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <g>
                    <g id="Layer_1_53_">
                      <g>
                        <path
                          d="M271.575,427.125c3.825-10.201,5.1-21.676,0-31.875c-6.375-15.301-21.675-25.5-36.975-25.5 c-5.1,0-8.925,1.275-14.025,2.549L140.25,402.9V299.625c0-7.65-3.825-14.025-10.2-17.851c-2.55-1.275-6.375-2.55-8.925-2.55 c-3.825,0-7.65,1.275-11.475,3.825l-87.975,59.925C7.65,351.9,0,368.475,0,385.049v91.801c0,24.225,17.85,45.9,42.075,49.725 l42.075,7.65c2.55,0,6.375,1.275,8.925,1.275c20.4,0,39.525-12.75,47.175-33.15l5.1-14.025l104.55-38.25 C258.825,444.975,267.75,437.324,271.575,427.125z">
                        </path>
                        <path
                          d="M415.65,36.975c-76.5,0-140.25,54.825-154.275,127.5L204,156.825c-15.3-2.55-29.325,8.925-30.6,22.95l-11.475,81.6 c8.925,10.2,15.3,24.225,15.3,38.25v51L209.1,339.15c8.925-2.551,17.85-5.102,26.775-5.102c31.875,0,59.925,19.125,71.4,48.451 c7.649,19.125,7.649,40.799-1.275,59.924s-24.225,34.426-43.35,40.801l-56.1,20.4l138.976,19.125 c15.3,2.549,29.324-8.926,30.6-22.951l20.4-149.174c6.375,1.275,12.75,1.275,19.125,1.275 c86.699,0,156.824-70.126,156.824-156.826C572.475,108.375,502.35,36.975,415.65,36.975z M392.7,182.325l-24.226-3.825 c6.375-20.4,25.5-35.7,48.45-35.7c28.05,0,51,22.95,51,51c0,28.05-22.95,51-51,51c-1.274,0-2.55,0-3.825,0l3.825-30.6 C418.2,197.625,406.725,184.875,392.7,182.325z M415.65,313.65c-5.101,0-8.926,0-14.025-1.275l3.825-31.875 c2.55,0,6.375,0,8.925,0c48.45,0,86.7-39.525,86.7-86.7c0-48.45-39.525-86.7-86.7-86.7c-40.8,0-76.5,29.325-85.425,67.575 l-31.875-3.825c11.475-54.825,59.925-95.625,117.3-95.625c66.3,0,119.85,53.55,119.85,119.85 C534.225,261.375,481.95,313.65,415.65,313.65z">
                        </path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <h3 class="mt-6 text-center text-base font-semibold leading-7 tracking-tight text-slate-600">
                @if(!checkingIn){
                Check-In Participant
                }@else {
                <span class="inline-flex items-center px-4 py-2 ">
                  <svg class="animate-spin -ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                    </path>
                  </svg>
                  Processing...
                </span>
                }
              </h3>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
