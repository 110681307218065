import { Component, OnInit } from '@angular/core';
import { MeetingService } from 'src/app/shared/service/meeting.service';

@Component({
  selector: 'app-meeting-actions',
  templateUrl: './meeting-actions.component.html',
  styleUrl: './meeting-actions.component.scss'
})
export class MeetingActionsComponent implements OnInit {
  isLoadingActive: boolean = false;
  isLoadingInactive: boolean = false;

  inactiveAccounts: number = 0
  activeAccounts: number = 0;

  constructor(
    private meetingService: MeetingService,
  ) { }

  ngOnInit(): void {
    this.getStatusInactive();
    this.getStatusActive();
  }

  getStatusInactive() {
    this.isLoadingInactive = true;
    return this.meetingService.getMeetingsInactiveAccount().subscribe({
      next: (result) => {
        this.inactiveAccounts = result;
        this.isLoadingInactive = false;
      },
      error: (error) => {
        this.isLoadingInactive = false;
      }
    });
  }

  getStatusActive() {
    this.isLoadingActive = true;
    return this.meetingService.getMeetingsActivatedAccount().subscribe({
      next: (result) => {
        console.log(result)
        this.activeAccounts = result;
        this.isLoadingActive = false;
      },
      error: (error) => {
        this.isLoadingActive = false;
      }
    });
  }

  startProcess(){
    this.meetingService.startCreatingBizAccounts().subscribe();
  }

}
