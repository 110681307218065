import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { NotificationService } from '../components/notifications/notification.service';
import { NotificationType } from '../model/enum/notification-type.enum';
import { AppSettingService } from './app-setting.service';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})
export class MeetingService {
  private url: string;

  constructor(
    private httpClient: HttpClient,
    private appSettings: AppSettingService,
    private eventService: EventService,
    private notification: NotificationService,
  ) {
    this.url = `${this.appSettings.settings.url}/meetings`;
  }


  getMeetingsActivatedAccount() {
    const eventId: string = this.eventService.selectedEvent.value.id;
    const endpoint: string = `${this.url}/count/active/${eventId}`;
    return this.httpClient.get<number>(endpoint);
  }

  getMeetingsInactiveAccount() {
    const eventId: string = this.eventService.selectedEvent.value.id;
    const endpoint: string = `${this.url}/count/inactive/${eventId}`;
    return this.httpClient.get<number>(endpoint);
  }

  startCreatingBizAccounts() {
    const eventId: string = this.eventService.selectedEvent.value.id;
    const endpoint: string = `${this.url}/create-accounts/${eventId}`;

    const api$ = this.httpClient.post<boolean>(endpoint, {});
    return api$.pipe(
      tap({
        next: status => {
          if (status) {
            this.notification.notify('Meeting', 'Creating accounts process has been started.', NotificationType.Success);
          } else {
            this.notification.notify('Meeting Error', 'Accounts have not been started.', NotificationType.Error);
          }
        }
      })
    );
  }
}
