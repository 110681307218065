import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { utcToZonedTime } from 'date-fns-tz';
import { debounceTime, distinctUntilChanged, Observable, Subject, Subscription, switchMap } from 'rxjs';
import { ActionsService } from 'src/app/shared/service/actions.service';
import { AttendeesService } from 'src/app/shared/service/attendees.service';
import { BadgeService } from 'src/app/shared/service/badge.service';
import { EventService } from 'src/app/shared/service/event.service';
import { PrinterService } from 'src/app/shared/service/printer.service';

@Component({
  selector: 'badges',
  templateUrl: './badges.component.html',
  styleUrl: './badges.component.scss',
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ]),
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' }))
      ])
    ])
  ]
})
export class BadgesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('badge') badge: ElementRef<HTMLElement>;
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  searchText$ = new Subject<string>();
  isEditModal = false;
  isOptionModal: boolean = false;
  isMenu = false;

  keyword: string = '';
  selectedProfile;
  qrCodeUrl;
  result$!: Observable<any>;

  base64Image: string;
  base64String: string;

  salutation: string;
  firstName: string;
  lastName: string;
  company: string;
  occupation: string;
  country: string;
  checkInDate: Date;

  checkingIn: boolean = false;
  sendingWE: boolean = false;
  searching: boolean = false;
  searchObs: Subscription;
  ready: boolean = false;

  checkpoints: string[] = [];
  selectedCheckPoint: string;

  constructor(
    private route: ActivatedRoute,
    private eventService: EventService,
    private badgeService: BadgeService,
    private printer: PrinterService,
    private actionsService: ActionsService,
    private attendeeService: AttendeesService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
      this.searchInput.nativeElement.select();
    }, 1000);

    this.result$ = this.searchText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(keyword => this.badgeService.search(keyword)),
    );

    this.searchObs = this.badgeService.searching.subscribe({
      next: status => this.searching = status,
    });

    this.checkpoints = this.eventService.selectedEvent.value.checkPoints;
  }

  ngOnDestroy(): void {
    if (this.searchObs) this.searchObs.unsubscribe();
  }

  ngAfterViewInit(): void {
    const profileId = this.route.snapshot.paramMap.get('profileId');
    if (profileId && profileId.length > 1) {
      this.searchInput.nativeElement.value = profileId;
      this.search(profileId);
    }
    this.ready = true;
  }

  getValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  search(keyword) {
    this.keyword = keyword;
    this.searchText$.next(keyword);
  }

  onSelect(profile) {
    this.selectedProfile = profile;
    this.salutation = this.selectedProfile.salutation;
    this.firstName = this.selectedProfile.firstName;
    this.lastName = this.selectedProfile.lastName;
    this.occupation = this.selectedProfile.occupation;
    this.company = this.selectedProfile.company;
    this.country = this.selectedProfile.country?.name;
    if (this.selectedProfile.checkingDetails && this.selectedProfile.checkingDetails.length > 0) {
      const date: string = this.selectedProfile.checkingDetails[this.selectedProfile.checkingDetails.length - 1]?.date;
      console.log(this.selectedProfile.checkingDetails);
      console.log(date);
      this.checkInDate = utcToZonedTime(date, this.eventService.selectedEvent.value.schedule.timeZone.zone);
    }

    this.qrCodeUrl = '';
    this.getProfileQRCodeURL();
    this.focus();
  }

  getProfileQRCodeURL() {
    this.attendeeService.getAttendeeQrCodeURL(this.selectedProfile.id).subscribe({
      next: (result) => {
        this.qrCodeUrl = result.url;
        const urls: string[] = this.qrCodeUrl.split('/');
        const qrId = urls[urls.length - 1];
        this.qrCodeUrl = `${window.location.origin}/api/uploads/qr_codes/${qrId}`;
      },
      error: (er) => console.log(er),
    })
  }

  back() {
    this.selectedProfile = null;
    this.searchText$.next(this.keyword);
  }

  print() {
    this.onSelect(this.selectedProfile);
    const width = 377.95;
    const height = 529.13;
    const fileName = `${this.selectedProfile.firstName}${this.selectedProfile.lastName}`
    this.printer.downloadImage(this.badge, width, height, fileName);
    this.toggleOptionModal();
  }

  toggleModal() {
    this.isEditModal = !this.isEditModal;
  }

  toggleOptionModal() {
    this.isOptionModal = !this.isOptionModal;
  }

  toggleMenu() {
    this.isMenu = !this.isMenu;
  }

  save() {
    this.toggleModal();
    this.focus();
  }

  focus() {
    this.searchInput.nativeElement.focus();
  }

  cancel() {
    this.salutation = this.selectedProfile.salutation;
    this.firstName = this.selectedProfile.firstName;
    this.lastName = this.selectedProfile.lastName;
    this.occupation = this.selectedProfile.occupation;
    this.company = this.selectedProfile.company;
    this.country = this.selectedProfile.country?.name;
    this.toggleModal();
    this.focus();
  }

  checkIn() {
    if (this.checkingIn) return;

    this.checkingIn = true;

    this.actionsService
      .checkIn(this.selectedProfile, this.selectedCheckPoint)
      .subscribe({
        next: _ => this.checkingIn = false,
        error: _ => this.checkingIn = false,
      });
    this.focus();
    this.toggleOptionModal();
  }

  onSelectCheckPoint(checkPoint: string) {
    this.selectedCheckPoint = checkPoint;
    this.toggleMenu();
  }
}
