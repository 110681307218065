import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, tap } from 'rxjs';
import { User } from 'src/app/shared/model/user.model';
import { AppSettingService } from './app-setting.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  session: BehaviorSubject<User> = new BehaviorSubject(new User());

  constructor(
    private oauthService: OAuthService,
    private appSettings: AppSettingService,
    private httpClient: HttpClient
  ) { }

  getCurrentSession() {
    const url: string = `${this.appSettings.settings.url}/sessions/current-session`;

    return this.httpClient
      .get<User>(url)
      .pipe(
        tap(user => this.session.next(user)),
      );
  }

  logout() {
    this.oauthService.logOut();
  }

}
