<div class="pt-11 pb-20 px-11">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-5xl lg:mx-0">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">BizMatch Account Status</h2>
      <p class="mt-6 text-lg leading-8 text-gray-600">
        Keep track of your event participants' BizMatch accounts. See how many have already created their accounts and
        how many still need to. Take action to ensure everyone is set up for successful networking.
      </p>
    </div>
    <div class="mx-auto mt-16 max-w-2xl lg:max-w-none">
      <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
        <div class="flex flex-col border border-slate-200 px-4 py-6 rounded-md">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-main">
              <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941" />
              </svg>
            </div>
            Created Accounts
          </dt>
          <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
            <p class="flex-auto">Participants who have successfully created their BizMatch accounts.</p>
            <p class="mt-6">
              @if(!isLoadingActive){
              <a class="text-sm font-semibold leading-6 text-main"><span aria-hidden="true">{{ activeAccounts }} Created
                  Accounts.</span></a>
              }@else {
            <div class="flex items-center">
              <svg class="animate-spin h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                </path>
              </svg>
            </div>
            }
            </p>
          </dd>
        </div>
        <div class="flex flex-col border border-slate-200 px-4 py-6 rounded-md">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-main">
              <svg class="size-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M2.25 6 9 12.75l4.286-4.286a11.948 11.948 0 0 1 4.306 6.43l.776 2.898m0 0 3.182-5.511m-3.182 5.51-5.511-3.181" />
              </svg>
            </div>
            Missing Accounts
          </dt>
          <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
            <p class="flex-auto">See participants who haven’t created their BizMatch accounts yet and take action.</p>
            <p class="mt-6">
              @if(!isLoadingInactive){
              <a routerLink="../custom-email" class="text-sm font-semibold leading-6 text-main"> <span
                  aria-hidden="true">{{ inactiveAccounts}} Missing Accounts</span></a>
              }@else {
            <div class="flex items-center">
              <svg class="animate-spin h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                </path>
              </svg>
            </div>
            }
            </p>
          </dd>
        </div>
        <div class="flex flex-col border border-slate-200 px-4 py-6 rounded-md">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-main">
              <svg class="size-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672ZM12 2.25V4.5m5.834.166-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243-1.59-1.59" />
              </svg>
            </div>
            Take Actions
          </dt>
          <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
            <p class="flex-auto">Automatically generate BizMatch accounts for participants who don’t have one..</p>
            <p class="mt-6">
              @if(!isLoadingInactive && !isLoadingActive){
              <a (click)="startProcess()" class="text-sm font-semibold leading-6 text-main"> <span aria-hidden="true">Create Missing
                  Account</span></a>
              }@else {
            <div class="flex items-center">
              <svg class="animate-spin h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                </path>
              </svg>
            </div>
            }
            </p>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</div>
