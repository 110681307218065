import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/shared/components/notifications/notification.service';
import { defaultImageTypesSupported } from 'src/app/shared/model/accepted-upload-formats';
import { BadgeConfiguration } from 'src/app/shared/model/badge/badge-configuration.model';
import { NotificationType } from 'src/app/shared/model/enum/notification-type.enum';
import { AppSettingService } from 'src/app/shared/service/app-setting.service';
import { BadgeService } from 'src/app/shared/service/badge.service';
import { EventService } from 'src/app/shared/service/event.service';
import { FileUploaderService } from 'src/app/shared/service/file-uploader.service';

@Component({
  selector: 'badge-theme',
  templateUrl: './badge-theme.component.html',
  styleUrl: './badge-theme.component.scss'
})
export class BadgeThemeComponent implements OnInit {
  saving: boolean = false;
  badgeConfiguration: BadgeConfiguration;

  constructor(
    private eventService: EventService,
    public appSettings: AppSettingService,
    private badgeService: BadgeService,
    private notificationService: NotificationService,
    private fileUploader: FileUploaderService) { }

  ngOnInit(): void {
    this.loadBanner();
  }

  loadBanner() {
    this.saving = true;
    this.badgeService
      .getBadgeConfiguration()
      .subscribe({
        next: (configuration) => {
          if (configuration) this.badgeConfiguration = configuration;
          this.saving = false;
          console.log(configuration);
        },
        error: (err) => {
          console.log(err);
          this.saving = false;
        }
      });
  }

  isBannerExists() {
    return this.badgeConfiguration?.banner?.name?.length > 0;
  }

  onSelectFile(selected: any) {
    this.saving = true;
    const endpoint: string = `badge-configuration/upload-banner`;
    this.uploadBanner(selected, endpoint);
  }

  uploadBanner(selected: any, url: string) {
    const eventId: string = this.eventService.selectedEvent.value.id;
    const endpoint: string = `${url}/${eventId}`;
    this.fileUploader.onFileSelect<BadgeConfiguration>(selected, endpoint, this.appSettings.settings.maxImageSizeMo, defaultImageTypesSupported).subscribe({
      next: configuration => {
        if (configuration) {
          this.badgeConfiguration = configuration;
          this.notificationService.notify('Update Banner', 'Configuration saved', NotificationType.Success);
        };
        this.saving = false;
      },
      error: error => {
        this.notificationService.notify('Update Banner', 'Configuration not saved', NotificationType.Error);
        console.log(error);
        this.saving = false;
      }
    });
  }

}
