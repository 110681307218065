import { Component, OnInit } from '@angular/core';
import { RegistrationAnalyticsService } from 'src/app/shared/service/registration-analytics.service';

@Component({
  selector: 'event-basic-stats',
  templateUrl: './basic-stats.component.html',
  styleUrl: './basic-stats.component.scss'
})
export class BasicStatsComponent implements OnInit {
  countAll: string = 'countAttendees';
  countActivated: string = 'countActivatedAccount';
  countByDate: string = 'countByCreateDate';

  loading: string[] = [];
  countAttendees: number = 0;
  countActivatedAccount: number = 0;
  countByCreationDate: any[] = [];

  constructor(
    private registrationAnalytics: RegistrationAnalyticsService
  ) { }

  ngOnInit(): void {
    this.fetchCountAttendees();
    this.fetchActivatedAccount();
    this.fetchCountByCreateDate();
  }

  fetchCountAttendees() {
    this.loading.push(this.countAll);
    this.registrationAnalytics
      .countAttendees()
      .subscribe({
        next: (result) => {
          this.removeLoadingStatus(this.countAll);
          this.countAttendees = result;
        },
        error: (_) => {
          this.removeLoadingStatus(this.countAll);
        }
      });
  }

  fetchActivatedAccount() {
    this.loading.push(this.countActivated);
    this.registrationAnalytics
      .countActivatedAccount()
      .subscribe({
        next: (result) => {
          this.removeLoadingStatus(this.countActivated);
          this.countActivatedAccount = result;
        },
        error: (_) => {
          this.removeLoadingStatus(this.countActivated);
        }
      });
  }

  fetchCountByCreateDate() {
    this.loading.push(this.countByDate);
    this.registrationAnalytics
      .countByCreateDate()
      .subscribe({
        next: (result) => {
          this.removeLoadingStatus(this.countByDate);
          this.countByCreationDate = [];
          for (const [key, value] of Object.entries(result)) {
            this.countByCreationDate.push({ day: key, count: value });
          }
          // Sort the array by date in descending order
          this.countByCreationDate.sort((a, b) => new Date(b.day).getTime() - new Date(a.day).getTime());
        },
        error: (_) => {
          this.removeLoadingStatus(this.countByDate);
        }
      });
  }

  removeLoadingStatus(status: string) {
    this.loading = this.loading.filter(loading => loading != status);
  }

  isLoading(status) {
    return this.loading.includes(status);
  }

}
