import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs';
import { NotificationService } from '../components/notifications/notification.service';
import { NotificationType } from '../model/enum/notification-type.enum';
import { AppSettingService } from './app-setting.service';
import { ErrorService } from './error.service';
import { EventService } from './event.service';

export interface CustomEmailModel{
  subject: string,
  htmlContent: string,
}

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private appSetting: AppSettingService,
    private httpClient: HttpClient,
    private eventService: EventService,
    private errorService: ErrorService,
    private notifyService: NotificationService,
  ) { }


  public sendUpdatePasswordEmail(userId: string) {
    const url: string = `${this.appSetting.settings.url}/email-profile/update-password?userId=${userId}`;
    const api$ = this.httpClient.post(url, {});

    return api$.pipe(
      catchError(error => {
        const notify = { title: 'Email', message: 'An error happend while trying to send this email please try again later.' }
        return this.errorService.handleError(error, notify, true);
      }),
    );
  }

  public genMagicLink(userId: string){
    const url: string = `${this.appSetting.settings.url}/profile/magic-link?userId=${userId}`;
    const api$ = this.httpClient.post(url, {});

    return api$.pipe(
      catchError(error => {
        const notify = { title: 'Email', message: 'An error happend while trying to send this email please try again later.' }
        return this.errorService.handleError(error, notify, true);
      }),
    );
  }

  public sendVerificationEmail(userId: string) {
    const url: string = `${this.appSetting.settings.url}/email-profile/verification-email?userId=${userId}`;
    const api$ = this.httpClient.post(url, {});

    return api$.pipe(
      catchError(error => {
        const notify = { title: 'Email', message: 'An error happend while trying to send this email please try again later.' }
        return this.errorService.handleError(error, notify, true);
      }),
    );
  }

  public sendConfirmationEmail(profileId: string) {
    const eventId: string = this.eventService.selectedEvent.value.id;
    const url: string = `${this.appSetting.settings.url}/email-profile/confirm-registration/event/${eventId}/participant/${profileId}`;

    const api$ = this.httpClient.post(url, {});

    return api$.pipe(
      catchError(error => {
        const notify = { title: 'Email', message: 'An error happend while trying to send this email please try again later.' }
        return this.errorService.handleError(error, notify, true);
      }),
    );
  }

  public sendConfirmationEmailToAttendees() {
    const eventId: string = this.eventService.selectedEvent.value.id;
    const url: string = `${this.appSetting.settings.url}/email-profile/confirm-registration/event/${eventId}`;

    const api$ = this.httpClient.post(url, {});

    return api$.pipe(
      tap( _ => this.notifyService.notify('Confirmation Email', 'An email has been sent to all attendees.', NotificationType.Success)),
      catchError(error => {
        const notify = { title: 'Email', message: 'An error happend while trying to send this email please try again later.' }
        return this.errorService.handleError(error, notify, true);
      }),
    );
  }

  public sendUpdatePasswordToNonVerifiedAttendees() {
    const eventId: string = this.eventService.selectedEvent.value.id;
    const url: string = `${this.appSetting.settings.url}/email-profile/update-password/event/${eventId}`;

    const api$ = this.httpClient.post(url, {});

    return api$.pipe(
      tap( _ => this.notifyService.notify('Confirmation Email', 'An email has been sent to non verified attendees.', NotificationType.Success)),
      catchError(error => {
        const notify = { title: 'Email', message: 'An error happend while trying to send this email please try again later.' }
        return this.errorService.handleError(error, notify, true);
      }),
    );
  }

  public sendCustomEmail(emailModel: CustomEmailModel) {
    const eventId: string = this.eventService.selectedEvent.value.id;
    const url: string = `${this.appSetting.settings.url}/email-profile/custom-email/event/${eventId}`;

    const api$ = this.httpClient.post(url, emailModel);

    return api$.pipe(
      tap( _ => this.notifyService.notify('Custom Email', 'An email is being sent to attendees.', NotificationType.Success)),
      catchError(error => {
        const notify = { title: 'Email', message: 'An error happend while trying to send this email please try again later.' }
        return this.errorService.handleError(error, notify, true);
      }),
    );
  }

}
