import { Organisation } from "./organisation.model";

export class User {
    public constructor(
        public id: string = '',
        public ssoId: string = '',
        public firstName: string = '',
        public lastName: string = '',
        public phoneNumber: string = '',
        public email: string = '',
        public profilePhoto: { name: string } = { name: '' },
        public emailVerified: boolean = false,
        public roles: string[] = [],
        public createDate: string = '',
        public organisations: Organisation[] = [],
        public profiles: any[] = [],
        public about: string = ''
    ) { }
}
