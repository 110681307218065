import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/shared/service/event.service';

@Component({
  selector: 'app-checkpoints',
  templateUrl: './checkpoints.component.html',
  styleUrl: './checkpoints.component.scss'
})
export class CheckpointsComponent implements OnInit {
  checkpoints: string[] = [];
  isLoading: boolean = false;
  selected: string;
  selectedIndex: number = -1;

  constructor(
    private eventService: EventService
  ) {

  }

  ngOnInit(): void {
    this.getCheckPoints();
  }

  getCheckPoints() {
    this.checkpoints = this.eventService.selectedEvent.value.checkPoints || [];
  }

  addCheckPoint() {
    this.isLoading = true;
    const newCheckPoint: string = `Check Point ${this.checkpoints.length + 1}`
    this.eventService
      .newCheckPoint(newCheckPoint, false, -1)
      .subscribe({
        next: _ => {
          this.getCheckPoints();
          this.isLoading = false;
        },
        error: _ => {
          this.isLoading = false;
        }
      });
  }

  remove() {
    this.isLoading = true;
    this.eventService
      .newCheckPoint('to_delete', true, this.selectedIndex)
      .subscribe({
        next: _ => {
          this.getCheckPoints();
          this.selectedIndex = -1;
          this.selected = null;
          this.isLoading = false;
        },
        error: _ => {
          this.isLoading = false;
        }
      });
  }

  save() {
    this.isLoading = true;
    this.eventService
      .newCheckPoint(this.selected, false, this.selectedIndex)
      .subscribe({
        next: _ => {
          this.getCheckPoints();
          this.isLoading = false;
        },
        error: _ => {
          this.isLoading = false;
        }
      });
  }

  onSelect(index) {
    this.selected = this.checkpoints[index];
    this.selectedIndex = index;
  }

}
