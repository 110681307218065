<div class="">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-5xl lg:mx-0">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Configure Badges</h2>
      <p class="mt-6 text-lg leading-8 text-gray-600">
        Configure your badge by uploading a custom header image to personalize the appearance of your event badges.
      </p>
    </div>
    <div class="mx-auto mt-16 max-w-2xl lg:max-w-none">
      <form>
        <div class="">
          <div class="border-b border-gray-900/10 pb-12">
            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="col-span-full">
                <label for="cover-photo" class="block text-base font-bold leading-6 text-gray-700">Upload Banner
                  Image</label>
                <div class="border-l-4 border-blue-400 bg-blue-50 p-4 my-4">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <svg class="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                        data-slot="icon">
                        <path fill-rule="evenodd"
                          d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495ZM10 5a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 10 5Zm0 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                          clip-rule="evenodd" />
                      </svg>
                    </div>
                    <div class="ml-3">
                      <p class="text-sm text-blue-700">
                        Upload a banner here with the dimensions <span
                          class="font-medium text-blue-700 underline hover:text-blue-600">10.4 cm (width) by 4 cm
                          (height)</span>, ensuring it fits perfectly within the badge design.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="relative mt-2 flex justify-center items-center rounded-lg border border-dashed border-gray-900/25 px-6 h-[4cm] w-[10.4cm]">
                  <img *ngIf="isBannerExists()"
                    class="absolute top-0 inline-block h-full w-full rounded-md object-cover"
                    [src]="badgeConfiguration?.banner?.name | image | async" alt>
                  <div class="z-10 text-center bg-slate-50/65 rounded-lg border border-dashed border-gray-200 w-[9cm] py-2.5">
                    @if ( !saving ) {
                    <svg class="mx-auto h-12 w-12 text-gray-500" viewBox="0 0 24 24" fill="currentColor"
                      aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                        clip-rule="evenodd" />
                    </svg>
                    <div class="mt-4 text-sm leading-6 text-gray-600">
                      <label for="file-upload" class="">
                        <span
                          class="cursor-pointer font-semibold text-main focus-within:outline-none focus-within:ring-2 focus-within:ring-main focus-within:ring-offset-2 hover:text-main/70">Upload
                          a file</span>
                        <span class="pl-1">or drag and drop
                          <span class="text-xs leading-5 text-gray-600">PNG, JPG up to {{
                            appSettings.settings.maxImageSizeMo
                            }}MB.</span>
                        </span>
                        <input (change)="onSelectFile($event)" id="file-upload" name="file-upload" type="file"
                          class="sr-only">
                      </label>
                    </div>
                    } @else {
                    <svg class="animate-spin mx-auto h-12 w-12 text-slate-700" xmlns="http://www.w3.org/2000/svg"
                      fill="none" viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                      </path>
                    </svg>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
