import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { User } from 'src/app/shared/model/user.model';
import { Profile } from 'src/app/shared/model/user/profile.model';
import { ActionsService } from 'src/app/shared/service/actions.service';

@Component({
  selector: 'attendee-actions',
  templateUrl: './attendee-actions.component.html',
  styleUrl: './attendee-actions.component.scss',
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ])
  ]
})
export class AttendeeActionsComponent implements OnChanges {
  @Input('attendee') attendee: Profile;
  @Input('displayOnly') toDisplay: string[] = [];
  @Input('user') user: User;

  isSendingEmail: string = '';
  isRemoveDialog: boolean = false;
  isRemoving: boolean = false;
  loading: string = '';

  constructor(
    private actionsService: ActionsService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
  }

  sendUpdatePasswordEmail() {
    this.isSendingEmail = 'verification';
    this.actionsService
      .sendUpdatePasswordEmail(this.attendee)
      .subscribe({
        next: _ => {
          this.isSendingEmail = '';
        },
        error: _ => this.isSendingEmail = '',
      });
  }

  sendVerificationEmail() {
    this.isSendingEmail = 'verification';
    this.actionsService
      .sendMagicLink(this.attendee)
      .subscribe({
        next: _ => {
          this.isSendingEmail = '';
        },
        error: _ => this.isSendingEmail = '',
      });
  }

  sendConfirmationEmail() {
    this.isSendingEmail = 'confirmation';
    this.actionsService
      .sendConfirmationEmail(this.attendee)
      .subscribe({
        next: _ => {
          this.isSendingEmail = '';
        },
        error: _ => this.isSendingEmail = '',
      });
  }

  remove() {
    this.isRemoving = true;
    this.actionsService
      .remove(this.attendee)
      .subscribe({
        next: _ => {
          this.isRemoving = false;
          this.toggleRemoveDialog();
        },
        error: _ => {
          this.toggleRemoveDialog();
          this.isRemoving = false;
        },
      })
  }

  toggleRemoveDialog() {
    this.isRemoveDialog = !this.isRemoveDialog;
  }

  setEventPresence() {
    this.loading = 'event_presence';
    this.actionsService
      .checkIn(this.attendee)
      .subscribe({
        next: _ => {
          this.attendee.presentAtEventVenue = true;
          this.loading = '';
        },
        error: _ => {
          this.loading = '';
        }
      });
  }

  canDisplay(action: string){
    if(this.toDisplay.length == 0 ) return true;

    return this.toDisplay.includes(action);
  }

}
