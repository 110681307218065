<div class="pt-11 pb-20 px-11 relative">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-7xl lg:mx-0">
      <div class="flex justify-between">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Meeting Configuration</h2>
        <button [disabled]="loading" type="button" [routerLink]="['../actions']"
          class="inline-flex items-center rounded-md bg-main px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
          <svg class="-ml-0.5 mr-1.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
          </svg>
          Check Stats
        </button>
      </div>


      <p class="mt-6 text-lg leading-8 text-gray-600">
        Include the following credentials to successfully link your application to the meeting platform. This will
        ensure seamless integration and functionality for your event.
      </p>
    </div>
    <div class="mx-auto mt-16 max-w-2xl lg:max-w-none">
      <div *ngIf="!loading && mode == 'create'" class="mt-8 rounded-md bg-blue-50 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm text-blue-700">You don't have any configuration, start by creating one!</p>
            <p class="mt-3 text-sm md:ml-6 md:mt-0">
              <a (click)="openModal()" class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
                Create New Configuration<span aria-hidden="true"> →</span>
              </a>
            </p>
          </div>
        </div>
      </div>

      <form *ngIf="!loading && mode && mode != 'create'" [formGroup]="form" (submit)="onSubmit()">
        <div class="space-y-12 sm:space-y-16">
          <div>
            <div
              class="space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label for="bizEventId" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Bizmatch
                  Event Id</label>
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <input type="number" name="bizEventId" formControlName="bizEventId" id="bizEventId"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label for="bizEventUrl" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Bizmatch
                  Event Url</label>
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <input type="text" name="bizEventUrl" formControlName="bizEventUrl" id="bizEventUrl"
                    placeholder="Business Matching Event URL"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label for="apiKey" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">API Key</label>
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <input type="text" name="apiKey" formControlName="apiKey" id="apiKey" placeholder="Secret Code"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xl sm:text-sm sm:leading-6">
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label for="jwtKey" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">JWT Key</label>
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <input type="text" name="jwtKey" formControlName="jwtKey" id="jwtKey" placeholder="Secret Code"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xl sm:text-sm sm:leading-6">
                </div>
              </div>

              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label for="createdAt" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Created
                  At</label>
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <p id="createdAt"
                    class="pl-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xl sm:text-sm sm:leading-6">
                    {{ configuration.createdAt | date:'MMM d, y, HH:mm (UTC-0)' }}
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="mt-6 flex items-center justify-end gap-x-6">
          <button type="submit"
            class="inline-flex justify-center rounded-md bg-main px-3 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main/70">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div @opacity *ngIf="isModal" class="relative z-[999]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
      <div @opacityTranslateY *ngIf="isModal"
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 text-left shadow-xl transition-all sm:my-3 max-w-2xl sm:p-6">
        <div>
          <div class="mt-3 text-start sm:mt-5">
            <form [formGroup]="form" (submit)="onSubmit()">
              <div class="space-y-12">
                <div class="pb-3">
                  <h2 (click)="toggleModal()" class="text-base font-semibold leading-7 text-gray-900">
                    New Meeting Configuration uuuuu
                  </h2>
                  <p class="mt-1 text-sm leading-6 text-gray-600">Avoid updating this forms if you
                    dont know what your are doing.</p>
                  <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-3">
                    <div class="col-span-full">
                      <label for="bizEventId" class="block text-sm font-medium leading-6 text-gray-900">
                        Biz Event ID
                      </label>
                      <div class="mt-2">
                        <div
                          class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main">
                          <input type="number" name="bizEventId" formControlName="bizEventId" id="bizEventId"
                            class="block flex-1 border-0 bg-transparent text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="Enter your business event ID">
                        </div>
                      </div>
                    </div>

                    <div class="col-span-full">
                      <label for="bizEventUrl" class="block text-sm font-medium leading-6 text-gray-900">
                        Biz Event URL
                      </label>
                      <div class="mt-2">
                        <div
                          class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main">
                          <input type="text" name="bizEventUrl" formControlName="bizEventUrl" id="bizEventUrl"
                            class="block flex-1 border-0 bg-transparent text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="Enter your business event URL">
                        </div>
                      </div>
                    </div>

                    <div class="col-span-full">
                      <label for="apiKey" class="block text-sm font-medium leading-6 text-gray-900">
                        API Key
                      </label>
                      <div class="mt-2">
                        <div
                          class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main">
                          <input type="text" name="apiKey" formControlName="apiKey" id="apiKey"
                            class="block flex-1 border-0 bg-transparent text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="Enter your business event URL">
                        </div>
                      </div>
                    </div>

                    <div class="col-span-full">
                      <label for="jwtKey" class="block text-sm font-medium leading-6 text-gray-900">
                        JWT Key
                      </label>
                      <div class="mt-2">
                        <div
                          class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main">
                          <input type="text" name="jwtKey" formControlName="jwtKey" id="jwtKey"
                            class="block flex-1 border-0 bg-transparent text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="Enter your business event URL">
                        </div>
                      </div>
                    </div>

                    <div class="col-span-full flex gap-x-2">
                      <button type="button" (click)="closeModal()"
                        class="w-full bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ">
                        Cancel
                      </button>
                      <button type="submit"
                        class="w-full rounded-md bg-main px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
