<div *ngIf="isOffCanvasMenuDialog" class="relative z-50 lg:hidden" role="dialog" aria-modal="true">
  <div @opacity *ngIf="isOffCanvasMenu" class="fixed inset-0 bg-gray-900/80"></div>

  <div @translateX *ngIf="isOffCanvasMenu" class="fixed inset-0 flex">
    <div *ngIf="isOffCanvasMenu" (click)="toggleOffCanvasMenu()" class="fixed inset-0"></div>
    <div @translateX *ngIf="isOffCanvasMenu" class="relative mr-16 flex w-full max-w-xs flex-1">

      <div @opacityInOut *ngIf="isOffCanvasMenu" class="absolute left-full top-0 flex w-16 justify-center pt-5">
        <button type="button" (click)="toggleOffCanvasMenu()" class="-m-2.5 p-2.5">
          <span class="sr-only">Close sidebar</span>
          <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4 h-full">
        <div class="mt-4 flex flex-col h-16 shrink-0 items-center justify-center gap-2.5">
          <img class="h-14 w-14 object-cover rounded-md" [src]="logo | image | async" [alt]="event.name">
          <div>
            <h2 class="text-slate-700 leading-3 font-medium">{{event.name }}</h2>
          </div>
        </div>
        <nav-menu (toggleOffCanvasMenuEvent)="toggleOffCanvasMenu()" class="h-full mt-3.5"></nav-menu>
      </div>
    </div>
  </div>
</div>

<!-- Static sidebar for desktop -->
<div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col h-full">
  <div class="flex grow flex-col overflow-y-auto border-r border-gray-200 bg-white pb-4 h-full">
    <div class="w-28 mt-4 flex flex-col h-16 shrink-0 items-center justify-start gap-2.5">
      <a [routerLink]="'../..'">
        <img class="h-12 w-12 object-cover rounded-md" [src]="logo | image | async" [alt]="event.name">
      </a>
      <div>
        <!-- <h2 class="text-slate-800 leading-4 font-medium line-clamp-1">{{event.name }}</h2> -->
      </div>
    </div>
    <nav-menu-desk class="h-full"></nav-menu-desk>
  </div>
</div>
