@if (configuration.formStatus.toString() == 'Disabled') {
    <floating-banners
    [title]="'Edit Form'"
    [text]="'This form is not enabled to participants to use please click to update'"
    [bgColor]="'bg-gradient-to-r from-yellow-300 to-orange-400'"
    [textColor]="'text-white'"
    (onAction)="editFormStatus()"></floating-banners>
} @else {
    <floating-banners
    [title]="'Edit Form'"
    [text]="'This form is available to participants to use please click to update'"
    [bgColor]="'bg-gradient-to-r from-lime-400 to-lime-700'"
    [textColor]="'text-white'"
    (onAction)="editFormStatus()"></floating-banners>
}


<div class="mx-auto max-w-7xl pt-2.5 lg:flex lg:gap-x-4 lg:px-2.5">
  <h1 class="sr-only">General Settings</h1>

  <aside class="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-52 lg:flex-none lg:border-0">
    <nav class="flex-none px-4 sm:px-6 lg:px-0">
      <ul role="list" class="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
        <li>
          <a (click)="onTabChange('FormDetails')" [ngClass]="{'bg-gray-50 text-main': currentTab == 'FormDetails'}"
            class="text-gray-700 hover:text-main hover:bg-gray-50 group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold">
            <svg class="h-6 w-6 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M4.5 12a7.5 7.5 0 0 0 15 0m-15 0a7.5 7.5 0 1 1 15 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077 1.41-.513m14.095-5.13 1.41-.513M5.106 17.785l1.15-.964m11.49-9.642 1.149-.964M7.501 19.795l.75-1.3m7.5-12.99.75-1.3m-6.063 16.658.26-1.477m2.605-14.772.26-1.477m0 17.726-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205 12 12m6.894 5.785-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
            </svg>
            Details
          </a>
        </li>
        <li>
          <a (click)="onTabChange('FormBuilder')" [ngClass]="{'bg-gray-50 text-main': currentTab == 'FormBuilder'}"
            class="text-gray-700 hover:text-main hover:bg-gray-50 group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold">
            <svg class="h-6 w-6 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z" />
            </svg>
            Form Builder
          </a>
        </li>
        <li>
          <a (click)="onTabChange('FormConfiguration')"
            [ngClass]="{'bg-gray-50 text-main': currentTab == 'FormConfiguration'}"
            class="text-gray-700 hover:text-main hover:bg-gray-50 group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold">
            <svg class="h-6 w-6 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M6 13.5V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 9.75V10.5" />
            </svg>
            Configuration
          </a>
        </li>
        <li>
          <a (click)="onTabChange('FormTickets')" [ngClass]="{'bg-gray-50 text-main': currentTab == 'FormTickets'}"
            class="text-gray-700 hover:text-main hover:bg-gray-50 group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold">
            <svg class="h-6 w-6 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z" />
            </svg>
            Tickets
          </a>
        </li>
        <li>
          <a (click)="onTabChange('LegalLinks')" [ngClass]="{'bg-gray-50 text-main': currentTab == 'LegalLinks'}"
            class="text-gray-700 hover:text-main hover:bg-gray-50 group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold w-40 text-ellipsis">
            <svg class="h-6 w-6 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
            </svg>
            Legal Links
          </a>
        </li>
        <li>
          <a (click)="onTabChange('FormQuestions')" [ngClass]="{'bg-gray-50 text-main': currentTab == 'FormQuestions'}"
            class="text-gray-700 hover:text-main hover:bg-gray-50 group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold w-40 text-ellipsis">
            <svg class="h-6 w-6 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
            </svg>
            FAQs
          </a>
        </li>
      </ul>
    </nav>
  </aside>
  <main class="px-4 py-4 sm:px-6 lg:flex-auto lg:px-0">
    <div class="mx-auto w-full space-y-16 sm:space-y-20 lg:mx-0">
      <div *ngIf="currentTab == 'FormBuilder'">
        <form-builder></form-builder>
      </div>
      <div class="w-full" *ngIf="currentTab == 'FormDetails'">
        <form-details></form-details>
      </div>
      <div *ngIf="currentTab == 'FormConfiguration'">
        <form-configuration></form-configuration>
      </div>
      <div *ngIf="currentTab == 'FormTickets'">
        <form-tickets></form-tickets>
      </div>
      <div *ngIf="currentTab == 'FormQuestions'">
        <form-question [form]="form"></form-question>
      </div>
      <div *ngIf="currentTab == 'LegalLinks'">
        <form-legal-links [form]="form"></form-legal-links>
      </div>
    </div>
  </main>
</div>
