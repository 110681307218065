<div>


  <div>
    <div>
      <img class="h-32 w-full object-cover lg:h-48 rounded-tr-md" [src]="event.theme.banner.name | image | async"
        alt="">
    </div>
    <div class="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
      <div class="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
        <div class="flex">
          @if (profilePicture) {
          <img class="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32" [src]="profilePicture | image | async"
            alt="">
          } @else {
          <svg class="h-24 w-24 rounded-full ring-4 bg-white shadow ring-white sm:h-32 sm:w-32 text-slate-500"
            data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z">
            </path>
          </svg>
          }
        </div>
        <div class="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
          <div class="mt-12 min-w-0 flex-1 sm:hidden md:block">
            <h1 class="truncate text-2xl font-bold text-gray-900">{{ attendee.salutation }} {{ attendee.firstName }} {{
              attendee.lastName }}</h1>
            <p class="truncate text-base text-gray-500">{{ attendee.occupation }}</p>
            <p class="truncate text-sm text-gray-500" *ngIf="attendee.company">{{ attendee.company }}</p>
          </div>
          <div class="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
            <attendee-actions [attendee]="attendee" [user]="user" [displayOnly]="displayOnly"></attendee-actions>
          </div>
        </div>
      </div>
      <div class="mt-6 hidden min-w-0 flex-1 sm:block md:hidden">
        <h1 class="truncate text-2xl font-bold text-gray-900">{{ attendee.salutation }} {{ attendee.firstName }} {{
          attendee.lastName }}</h1>
        <p class="truncate text-base text-gray-500">{{ attendee.occupation }}</p>
        <p class="truncate text-sm text-gray-500" *ngIf="attendee.company">{{ attendee.company }}</p>
      </div>
    </div>
  </div>


  <div class="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
    <div class="mt-20">
      <div class="border-b border-gray-200">
        <div class="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
          <nav class="-mb-px flex space-x-8" aria-label="Tabs">
            <a (click)="onTabChange('profile')" [ngClass]="{'border-main text-gray-900': currentTab == 'profile' }"
              class="text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
              aria-current="page">Profile</a>
            <a (click)="onTabChange('actions')" [ngClass]="{'border-main text-gray-900': currentTab == 'actions' }"
              class="text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium">Actions</a>
          </nav>
        </div>
      </div>
    </div>

    <div class="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
      @if ( currentTab == 'profile' ) {
      <attendee-profile-details [attendee]="attendee" [user]="user"></attendee-profile-details>
      }@else if (currentTab == 'actions') {
      <attendee-actions [attendee]="attendee" [user]="user"></attendee-actions>
      }

    </div>
  </div>
</div>
