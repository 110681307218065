import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { utcToZonedTime } from 'date-fns-tz';
import { User } from 'src/app/shared/model/user.model';
import { Profile } from 'src/app/shared/model/user/profile.model';
import { AttendeesService } from 'src/app/shared/service/attendees.service';
import { EventService } from 'src/app/shared/service/event.service';

@Component({
  selector: 'attendee-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrl: './profile-details.component.scss'
})
export class ProfileDetailsComponent implements OnChanges {
  @Input('attendee') attendee: Profile;
  @Input('user') user: User;
  qrCodeUrl: string = '';
  metaFieldKeys: string[];


  constructor(
    private attendeesService: AttendeesService,
    private eventService: EventService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.getProfileQRCodeURL();
    this.metaFieldKeys = Object.keys(this.attendee.metaField);
  }

  getProfileQRCodeURL() {
    this.attendeesService.getAttendeeQrCodeURL(this.attendee.id).subscribe({
      next: (result) => {
        this.qrCodeUrl = result.url;
      },
      error: (er) => console.log(er),
    })
  }

  toEventTimezone(date: string) {
    return utcToZonedTime(date, this.eventService.eventTZ.zone);
  }

  getLastChecking() {
    const checking = this.attendee.checkingDetails.length > 0 ? this.attendee.checkingDetails[this.attendee.checkingDetails.length - 1] : null;
    if (checking) {
      return utcToZonedTime(checking.date, this.eventService.eventTZ.zone);
    }
    return null;
  }

}
