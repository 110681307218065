<div class="px-2 md:px-7 py-7">
  <div class="mx-auto max-w-5xl">
    <nav class="flex mb-4" aria-label="Breadcrumb">
      <ol role="list" class="flex items-center space-x-4">
        <li>
          <div class="flex items-center justify-start">
            <a [routerLink]="['/event-editor', event.id, 'menu']" class="text-sm font-medium text-gray-500 hover:text-gray-700">Event
              Editor</a>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clip-rule="evenodd" />
            </svg>
            <a [routerLink]="['/event-editor', event.id, 'details']" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              aria-current="page">Streamline Event Details</a>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clip-rule="evenodd" />
            </svg>
            <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700" aria-current="page">Event
              Industries</a>
          </div>
        </li>
      </ol>
    </nav>
  </div>
  <div class="space-y-12 mt-7">
    <div class="mx-auto max-w-4xl lg:text-center">
      <h2 class="text-base font-semibold leading-7 text-main">Event Industries</h2>
      <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl line-clamp-2 text-ellipsis">{{
        event.name }}
      </p>
      <p class="mt-6 text-lg leading-8 text-gray-600">Upload a custom list of industries to tailor your event's focus
        with Industries. Easily categorize and engage attendees based on their professional interests.</p>
    </div>
  </div>
  <div class="mx-auto max-w-5xl mt-24">
    <div class="border-b border-gray-900/10 pb-12">
      <div class="grid max-w-full grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 col-span-2">
        <div class="col-span-full">
          <label for="cover-photo" class="block text-sm font-medium leading-6 text-gray-900">
            Upload list of industries
          </label>
          <div class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
            <div class="text-center">
              <svg *ngIf="!saving" class="mx-auto h-12 w-12 text-gray-300" xmlns="http://www.w3.org/2000/svg"
                fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M7.5 7.5h-.75A2.25 2.25 0 0 0 4.5 9.75v7.5a2.25 2.25 0 0 0 2.25 2.25h7.5a2.25 2.25 0 0 0 2.25-2.25v-7.5a2.25 2.25 0 0 0-2.25-2.25h-.75m0-3-3-3m0 0-3 3m3-3v11.25m6-2.25h.75a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-7.5a2.25 2.25 0 0 1-2.25-2.25v-.75" />
              </svg>
              <svg *ngIf="saving" class="animate-spin h-12 w-12 text-slate-700" xmlns="http://www.w3.org/2000/svg"
                fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                </path>
              </svg>
              <div class="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                <label for="upload-industry"
                  class="relative cursor-pointer rounded-md bg-white font-semibold text-main hover:text-main/70">
                  <span>Upload a file</span>
                  <input id="upload-industry" name="upload-industry" type="file" class="sr-only"
                    (change)="onSelectFile($event)">
                </label>
              </div>
              <p class="text-xs leading-5 text-gray-600">You can use this template to set up you industries list <a
                  class="text-main text-xs" (click)="getTemplate('industries-template.csv')">
                  Download
                </a></p>
              <p class="text-xs leading-5 text-gray-600">Or, you can use our proposed list<a
                  (click)="getTemplate('default_industries.csv')" class="text-main text-xs">
                  Download
                </a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-12">
      <div class="my-7">
        <p class="leading-7 text-slate-700 text-base">
          Easily proceed to <span class="font-bold">Event Sponsors</span> by clicking "Manage Sponsors." This button
          will guide you through updating sponsors of your event.
        </p>
      </div>
      <div class="flex">
        <div (click)="trackUpdate('Sponsors')" [routerLink]="['/event-editor', event.id, 'event-sponsors']"
          class="flex flex-col borded mt-4 md:w-1/2">
          <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
            <svg class="h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z"
                clip-rule="evenodd" />
            </svg>
            Event Sponsors
          </dt>
          <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
            <p class="flex-auto">Define and manage event sponsors effectively with Sponsors. Showcase their
              contributions
              and enhance event visibility through strategic partnerships.</p>
            <p class="mt-6 md:text-end">
              <button type="button"
                class="rounded-md inline-flex items-center gap-x-1.5 bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Manage Sponsors
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="-mr-0.5 h-5 w-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                </svg>
              </button>
            </p>
          </dd>
        </div>
      </div>
    </div>
  </div>
</div>
