<div class="px-2 md:px-7 py-7">
  <div class="mx-auto max-w-5xl">
    <nav class="flex mb-4" aria-label="Breadcrumb">
      <ol role="list" class="flex items-center space-x-4">
        <li>
          <div class="flex items-center justify-start">
            <a [routerLink]="['/event-editor', event.id, 'menu']"
              class="text-sm font-medium text-gray-500 hover:text-gray-700">Event Editor</a>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clip-rule="evenodd" />
            </svg>
            <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700" aria-current="page">Streamline Event
              Details</a>
          </div>
        </li>
      </ol>
    </nav>
  </div>
  <div class="space-y-12 mt-7">
    <div class="mx-auto max-w-4xl lg:text-center">
      <h2 class="text-base font-semibold leading-7 text-main">Event Editor</h2>
      <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl line-clamp-2 text-ellipsis">{{
        event.name }}
      </p>
      <p class="mt-6 text-lg leading-8 text-gray-600">Easily input and update crucial event details such as the event
        name, date, location, and a brief description. Ensure all foundational event information is accurately recorded
        and readily accessible in one convenient location.</p>
    </div>
  </div>
  <div class="mx-auto mt-24 max-w-5xl">
    <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
      <div (click)="trackUpdate('Basic Information')" [routerLink]="['/event-editor/', event.id ,'basic-information']"
        class="flex flex-col borded">
        <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
          <svg class="size-6 flex-none text-main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
            fill="currentColor">
            <path fill-rule="evenodd"
              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
              clip-rule="evenodd" />
          </svg>
          Basic Information
        </dt>
        <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
          <p class="flex-auto">Easily update event details like name, tags, languages, and description in one convenient
            menu. Streamline event setup for clear and accurate information.</p>
          <p class="mt-6 md:text-end">
            <button type="button"
              class="rounded-md inline-flex items-center gap-x-1.5 bg-main px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
              Manage Details
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="-mr-0.5 h-5 w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
              </svg>
            </button>
          </p>
        </dd>
      </div>
      <div (click)="trackUpdate('Event Type')" [routerLink]="['/event-editor', event.id, 'event-type']"
        class="flex flex-col borded">
        <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
          <svg class="h-6 w-6 flex-none fill-main text-main" viewBox="0 0 48 48" version="1.1"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
              <title>ic_fluent_conference_room_48_filled</title>
              <desc>Created with Sketch.</desc>
              <g id="🔍-Product-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="ic_fluent_conference_room_48_filled" fill="#0067f7" fill-rule="nonzero">
                  <path
                    d="M22.503257,7.77592448 L37.003257,10.7759245 C37.5837049,10.8960171 38,11.4072589 38,12 L38,36 C38,36.5927411 37.5837049,37.1039829 37.003257,37.2240755 L22.503257,40.2240755 C21.7274985,40.3845773 21,39.7921881 21,39 L21,9 C21,8.20781189 21.7274985,7.61542272 22.503257,7.77592448 Z M18,10 L18.1278052,37.9935464 L18,38 L11.25,38 C10.6027913,38 10.0704661,37.5081253 10.0064536,36.8778052 L10,36.75 L10,11.25 C10,10.6027913 10.4918747,10.0704661 11.1221948,10.0064536 L11.25,10 L18,10 Z M26.5,22.5 C25.6715729,22.5 25,23.1715729 25,24 C25,24.8284271 25.6715729,25.5 26.5,25.5 C27.3284271,25.5 28,24.8284271 28,24 C28,23.1715729 27.3284271,22.5 26.5,22.5 Z"
                    id="🎨-Color"> </path>
                </g>
              </g>
            </g>
          </svg>
          Event Type
        </dt>
        <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
          <p class="flex-auto">Choose from Exhibition, Conference, or Education to categorize your event effectively.
            Select the type that best suits your event's purpose and audience.</p>
          <p class="mt-6 md:text-end">
            <button type="button"
              class="rounded-md inline-flex items-center gap-x-1.5 bg-main px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
              Manage Type
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="-mr-0.5 h-5 w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
              </svg>
            </button>
          </p>
        </dd>
      </div>
      <div (click)="trackUpdate('Event Nature')" [routerLink]="['/event-editor', event.id, 'event-nature']"
        class="flex flex-col borded">
        <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
          <svg class="h-6 w-6 flex-none fill-main text-main" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
            <path
              d="M0 80V229.5c0 17 6.7 33.3 18.7 45.3l176 176c25 25 65.5 25 90.5 0L418.7 317.3c25-25 25-65.5 0-90.5l-176-176c-12-12-28.3-18.7-45.3-18.7H48C21.5 32 0 53.5 0 80zm112 32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
          </svg>
          Event Nature
        </dt>
        <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
          <p class="flex-auto">Select Online, Onsite, or Hybrid to define the nature of your event. Choose the option
            that best fits your event's format and accessibility requirements.</p>
          <p class="mt-6 md:text-end">
            <button type="button"
              class="rounded-md inline-flex items-center gap-x-1.5 bg-main px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
              Manage Nature
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="-mr-0.5 h-5 w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
              </svg>
            </button>
          </p>
        </dd>
      </div>
      <div (click)="trackUpdate('Cover')" [routerLink]="['/event-editor', event.id, 'event-cover']"
        lass="flex flex-col borded">
        <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
          <svg class="h-6 w-6 flex-none text-main" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
            fill="#0067f7">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <rect x="0" fill="none" width="20" height="20"></rect>
              <g>
                <path
                  d="M2.2 1h15.5c.7 0 1.3.6 1.3 1.2v11.5c0 .7-.6 1.2-1.2 1.2H2.2c-.6.1-1.2-.5-1.2-1.1V2.2C1 1.6 1.6 1 2.2 1zM17 13V3H3v10h14zm-4-4s0-5 3-5v7c0 .6-.4 1-1 1H5c-.6 0-1-.4-1-1V7c2 0 3 4 3 4s1-4 3-4 3 2 3 2zM4 17h12v2H4z">
                </path>
              </g>
            </g>
          </svg>
          Cover
        </dt>
        <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
          <p class="flex-auto">Upload your event's logo, banner, and background image easily with Cover. Enhance your
            event's visual identity and branding effortlessly.</p>
          <p class="mt-6 md:text-end">
            <button type="button"
              class="rounded-md inline-flex items-center gap-x-1.5 bg-main px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
              Manage Cover
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="-mr-0.5 h-5 w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
              </svg>
            </button>
          </p>
        </dd>
      </div>
      <div (click)="trackUpdate('Location')" [routerLink]="['/event-editor', event.id, 'event-location']"
        class="flex flex-col borded">
        <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
          <svg class="h-6 w-6 flex-none fill-main text-main" viewBox="0 0 24 24" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M13 3C13 2.44772 12.5523 2 12 2C11.4477 2 11 2.44772 11 3V4.06189C7.38128 4.51314 4.51314 7.38128 4.06189 11H3C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H4.06189C4.51314 16.6187 7.38128 19.4869 11 19.9381V21C11 21.5523 11.4477 22 12 22C12.5523 22 13 21.5523 13 21V19.9381C16.6187 19.4869 19.4869 16.6187 19.9381 13H21C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11H19.9381C19.4869 7.38128 16.6187 4.51314 13 4.06189V3ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8Z"
                fill="#0067f7"></path>
            </g>
          </svg>
          Location
        </dt>
        <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
          <p class="flex-auto">Enter the event's precise address, location, and country details seamlessly with
            Location. Provide accurate geographical information for attendees.</p>
          <p class="mt-6 md:text-end">
            <button type="button"
              class="rounded-md inline-flex items-center gap-x-1.5 bg-main px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
              Manage Location
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="-mr-0.5 h-5 w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
              </svg>
            </button>
          </p>
        </dd>
      </div>
      <div (click)="trackUpdate('Schedule')" [routerLink]="['/event-editor', event.id, 'event-schedule']"
        class="flex flex-col borded">
        <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
          <svg class="h-5 w-5 flex-none fill-main text-main" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
            <path
              d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm80 64c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80z" />
          </svg>
          Schedule
        </dt>
        <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
          <p class="flex-auto">Choose start and end dates, along with the timezone, effortlessly using Schedule. Ensure
            accurate timing details for your event planning needs.</p>
          <p class="mt-6 md:text-end">
            <button type="button"
              class="rounded-md inline-flex items-center gap-x-1.5 bg-main px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
              Manage Schedule
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="-mr-0.5 h-5 w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
              </svg>
            </button>
          </p>
        </dd>
      </div>
      <div (click)="trackUpdate('Industries')" [routerLink]="['/event-editor', event.id, 'event-industries']"
        class="flex flex-col borded">
        <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
          <svg class="h-5 w-5 flex-none fill-main text-main" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
            <path
              d="M64 32C46.3 32 32 46.3 32 64V304v48 80c0 26.5 21.5 48 48 48H496c26.5 0 48-21.5 48-48V304 152.2c0-18.2-19.4-29.7-35.4-21.1L352 215.4V152.2c0-18.2-19.4-29.7-35.4-21.1L160 215.4V64c0-17.7-14.3-32-32-32H64z" />
          </svg>
          Industries
        </dt>
        <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
          <p class="flex-auto">Upload a custom list of industries to tailor your event's focus with Industries. Easily
            categorize and engage attendees based on their professional interests.</p>
          <p class="mt-6 md:text-end">
            <button type="button"
              class="rounded-md inline-flex items-center gap-x-1.5 bg-main px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
              Manage Industries
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="-mr-0.5 h-5 w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
              </svg>
            </button>
          </p>
        </dd>
      </div>
      <div (click)="trackUpdate('Sponsors')" [routerLink]="['/event-editor', event.id, 'event-sponsors']"
        class="flex flex-col borded">
        <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
          <svg class="size-6 flex-none fill-main text-main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
            fill="currentColor">
            <path
              d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
          </svg>
          Sponsors
        </dt>
        <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
          <p class="flex-auto">Define and manage event sponsors effectively with Sponsors. Showcase their contributions
            and enhance event visibility through strategic partnerships.</p>
          <p class="mt-6 md:text-end">
            <button type="button"
              class="rounded-md inline-flex items-center gap-x-1.5 bg-main px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
              Manage Sponsors
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="-mr-0.5 h-5 w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
              </svg>
            </button>
          </p>
        </dd>
      </div>
      <div (click)="trackUpdate('Brochures')" [routerLink]="['/event-editor', event.id, 'event-brochures']"
        class="flex flex-col borded">
        <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
          <svg class="size-6 flex-none fill-main text-main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
            fill="currentColor">
            <path fill-rule="evenodd"
              d="M18.97 3.659a2.25 2.25 0 0 0-3.182 0l-10.94 10.94a3.75 3.75 0 1 0 5.304 5.303l7.693-7.693a.75.75 0 0 1 1.06 1.06l-7.693 7.693a5.25 5.25 0 1 1-7.424-7.424l10.939-10.94a3.75 3.75 0 1 1 5.303 5.304L9.097 18.835l-.008.008-.007.007-.002.002-.003.002A2.25 2.25 0 0 1 5.91 15.66l7.81-7.81a.75.75 0 0 1 1.061 1.06l-7.81 7.81a.75.75 0 0 0 1.054 1.068L18.97 6.84a2.25 2.25 0 0 0 0-3.182Z"
              clip-rule="evenodd" />
          </svg>
          Brochures
        </dt>
        <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
          <p class="flex-auto">Upload event brochures and related documents easily with Brochures. Provide attendees
            with essential information and resources conveniently.</p>
          <p class="mt-6 md:text-end">
            <button type="button"
              class="rounded-md inline-flex items-center gap-x-1.5 bg-main px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
              Manage Brochures
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="-mr-0.5 h-5 w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
              </svg>
            </button>
          </p>
        </dd>
      </div>
      <div (click)="trackUpdate('Payment Gateways')" [routerLink]="['/event-editor', event.id, 'payment-gateways']"
        class="flex flex-col borded">
        <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
          <svg class="size-6 flex-none fill-main text-main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
            fill="currentColor">
            <path
              d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 0 1-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004ZM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 0 1-.921.42Z" />
            <path fill-rule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v.816a3.836 3.836 0 0 0-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 0 1-.921-.421l-.879-.66a.75.75 0 0 0-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 0 0 1.5 0v-.81a4.124 4.124 0 0 0 1.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 0 0-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 0 0 .933-1.175l-.415-.33a3.836 3.836 0 0 0-1.719-.755V6Z"
              clip-rule="evenodd" />
          </svg>
          Payment Gateways
        </dt>
        <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
          <p class="flex-auto">Easily integrate and manage multiple payment gateways for seamless transactions to
            enhance your event registration experience with secure and efficient payment solutions.</p>
          <p class="mt-6 md:text-end">
            <button type="button"
              class="rounded-md inline-flex items-center gap-x-1.5 bg-main px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
              Manage Payment Gateway
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="-mr-0.5 h-5 w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
              </svg>
            </button>
          </p>
        </dd>
      </div>
    </dl>
  </div>
  <div *ngIf="event?.theme?.banner?.name" class="mx-auto max-w-7xl sm:px-6 lg:px-8 mt-20">
    <div
      class="mt-24 relative overflow-hidden bg-gray-900 px-6 py-20 shadow-xl sm:rounded-3xl sm:px-10 sm:py-24 md:px-12 lg:px-20">
      <img class="absolute inset-0 h-full w-full object-cover" [src]="event.theme.banner.name | image | async" alt="">

      <div class="absolute inset-0  bg-gradient-to-tr from-gray-900 via-gray-900/40"></div>
      <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>

      <div class="relative mx-auto max-w-2xl lg:mx-0">
        <p
          class="font-text mt-6 text-lg font-semibold text-white sm:text-xl sm:leading-8 text-truncate overflow-ellipsis line-clamp-4">
          {{ event.description }}
        </p>
      </div>
    </div>
  </div>
</div>
