<div class="px-2 md:px-7 py-7">
  <div class="mx-auto max-w-4xl">
    <nav class="flex mb-4" aria-label="Breadcrumb">
      <ol role="list" class="flex items-center space-x-4">
        <li>
          <div class="flex items-center justify-start">
            <a [routerLink]="['/event-editor',event.id,'menu']"
              class="text-sm font-medium text-gray-500 hover:text-gray-700">Event Editor</a>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clip-rule="evenodd" />
            </svg>
            <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700" aria-current="page">Tailor Your Event
              Experience</a>
          </div>
        </li>
      </ol>
    </nav>
  </div>
  <div class="space-y-12 mt-7">
    <div class="mx-auto max-w-4xl lg:text-center">
      <h2 class="text-base font-semibold leading-7 text-indigo-600">Event Editor</h2>
      <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl line-clamp-2 text-ellipsis">{{
        event.name }}
      </p>
      <p class="mt-6 text-lg leading-8 text-gray-600">Customize registration options, ticket types, pricing effortlessly
        in the Event
        Configuration and Settings section. Ensure your event meets every attendee's expectation.</p>
    </div>
    <div class="mx-auto max-w-5xl lg:max-w-5xl">
      <div class="mt-24 grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-10 pb-12">
        @if (refresh) {
        @for (setting of settings ; track $index) {
        <event-setting [event]="event" [setting]="setting.name" [displayName]="setting.displayName"
          [description]="setting.description" [icon]="setting.icon"></event-setting>
        }
        }
      </div>
    </div>
  </div>
  <div *ngIf="event?.theme?.banner?.name" class="mx-auto max-w-7xl sm:px-6 lg:px-8 mt-20">
    <div
      class="mt-24 relative overflow-hidden bg-gray-900 px-6 py-20 shadow-xl sm:rounded-3xl sm:px-10 sm:py-24 md:px-12 lg:px-20">
      <img class="absolute inset-0 h-full w-full object-cover" [src]="event.theme.banner.name | image | async" alt="">

      <div class="absolute inset-0  bg-gradient-to-tr from-gray-900 via-gray-900/40"></div>
      <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>

      <div class="relative mx-auto max-w-2xl lg:mx-0">
        <p
          class="font-text mt-6 text-lg font-semibold text-white sm:text-xl sm:leading-8 text-truncate overflow-ellipsis line-clamp-4">
          {{ event.description }}
        </p>
      </div>
    </div>
  </div>
</div>
