import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of, tap } from 'rxjs';
import { BadgeConfiguration } from '../model/badge/badge-configuration.model';
import { AppSettingService } from './app-setting.service';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})
export class BadgeService {
  searching: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private httpClient: HttpClient,
    private eventService: EventService,
    private appSettings: AppSettingService
  ) { }

  search(idKeyword: string) {
    if (idKeyword.length < 3) return of([]);

    this.searching.next(true);
    const eventId: string = this.eventService.selectedEvent.value.id;
    const url: string = `${this.appSettings.settings.url}/profile/search-for-badge?profileId=${idKeyword}&eventId=${eventId}`;
    const api$ = this.httpClient.get(url);
    return api$.pipe(
      tap(_ => this.searching.next(false)),
    );
  }

  getBadgeConfiguration() {
    const eventId: string = this.eventService.selectedEvent.value.id;
    const url: string = `${this.appSettings.settings.url}/badge-configuration/get-banner/${eventId}`;
    const api$ = this.httpClient.get<BadgeConfiguration>(url);
    return api$.pipe();
  }

}
