import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { menuModels } from 'src/app/event-editor/event-information/menus.model';
import { EventService } from './event.service';
import { UserService } from './session.service';

export interface LastUpdate {
  title: string,
  text: string,
  svg: string,
  routerLink: string
}

@Injectable({
  providedIn: 'root',
})
export class LastUpdatesTrackerService {
  private eventIDRpl = '<event_ID>';

  constructor(
    private userService: UserService,
    private eventService: EventService,
    private cookieService: CookieService,
  ) { }

  newUpdate(menuName: string) {
    const index = menuModels.findIndex(menuElement => menuElement.title == menuName);
    if (index == -1) return;

    const eventId = this.eventService.event.id;
    const element = menuModels[index];
    element.routerLink = element.routerLink.replace(this.eventIDRpl, eventId);

    const saved = this.getLastUpdates();

    const elementIndex = saved.findIndex(menuElement => menuElement.title == menuName);
    if (elementIndex == 0) {
      return;
    } else if (elementIndex > 0) {
      saved.splice(elementIndex, 1);
    }
    saved.splice(0, 0, element);
    this.cookieService.set(this.key, JSON.stringify(saved.map(e => e.title)));
  }

  getLastUpdates(): LastUpdate[] {
    if (!this.cookieService.check(this.key)) return [];
    const results: string = this.cookieService.get(this.key);
    const titles: string[] = JSON.parse(results);
    let menu: LastUpdate[] = [];
    titles.map(title => {
      const index = menuModels.findIndex(menuElement => menuElement.title == title);
      menu.push(menuModels[index]);
    })
    return menu;
  }

  get key() {
    const user = this.userService.session.value;
    return `LAST_UPDATE-${user.id}`;
  }

  alreadyExists() {

  }
}
