<div class="px-2 md:px-7 py-7">
  <div class="mx-auto max-w-5xl">
    <nav class="flex mb-4" aria-label="Breadcrumb">
      <ol role="list" class="flex items-center space-x-4">
        <li>
          <div class="flex items-center justify-start">
            <a [routerLink]="['/event-editor', event.id, 'menu']"  class="text-sm font-medium text-gray-500 hover:text-gray-700">Event
              Editor</a>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clip-rule="evenodd" />
            </svg>
            <a [routerLink]="['/event-editor', event.id, 'details']" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              aria-current="page">Streamline Event Details</a>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clip-rule="evenodd" />
            </svg>
            <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700" aria-current="page">Event Schedule</a>
          </div>
        </li>
      </ol>
    </nav>
  </div>
  <div class="space-y-12 mt-7">
    <div class="mx-auto max-w-4xl lg:text-center">
      <h2 class="text-base font-semibold leading-7 text-main">Event Schedule</h2>
      <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl line-clamp-2 text-ellipsis">{{
        event.name }}
      </p>
      <p class="mt-6 text-lg leading-8 text-gray-600">Choose start and end dates, along with the timezone, effortlessly
        using Schedule. Ensure accurate timing details for your event planning needs.</p>
    </div>
  </div>
  <div class="mx-auto max-w-5xl mt-24">
    <form [formGroup]="form" (submit)="onSubmit()">
      <div class="border-b border-gray-900/10 pb-12">
        <div class="grid max-w-full grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 col-span-2">
          <div class="col-span-full">
            <label for="from" class="block text-sm font-medium leading-6 text-gray-900">
              From
            </label>
            <div class="mt-2">
              <div
                class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main">
                <nz-date-picker id="from" nzPlaceHolder=" " formControlName="from" nzBorderless
                  [nzShowTime]="{'nzFormat': 'HH:mm'}" [nzDefaultPickerValue]="startAt" [nzFormat]="'yyyy-MM-dd HH:mm'"
                  class="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"></nz-date-picker>
              </div>
            </div>
          </div>

          <div class="col-span-full">
            <label for="to" class="block text-sm font-medium leading-6 text-gray-900">
              To
            </label>
            <div class="mt-2">
              <div
                class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main">
                <nz-date-picker id="to" nzPlaceHolder=" " nzBorderless [nzShowTime]="{'nzFormat': 'HH:mm'}"
                  formControlName="to" [nzDefaultPickerValue]="endAt" nzFormat="yyyy-MM-dd HH:mm"
                  class="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"></nz-date-picker>
              </div>
            </div>
          </div>

          <div class="col-span-full">
            <label for="timezones" class="block text-sm font-medium leading-6 text-gray-900">
              Timezone
            </label>
            <div class="mt-2 relative">
              <input id="timezones" (click)="toggleTimezone()" (keyup)="filterZones($event)" type="text"
                [value]="selectedTimezone.label" formControlName="timezone"
                class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6"
                role="combobox" aria-controls="options" aria-expanded="false">
              <button type="button" (click)="toggleTimezone()"
                class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                    clip-rule="evenodd" />
                </svg>
              </button>
              <ul *ngIf="showDropdownTimezone"
                class="absolute z-10 mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                id="options" role="listbox">
                <li *ngFor="let timezone of timezones" (click)="onSelectTimezone(timezone)"
                  (mouseenter)="activeTimezone = timezone" (mouseleave)="activeTimezone = null"
                  class="hover:text-white hover:bg-main relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900"
                  id="option-0" role="option" tabindex="-1">
                  <span class="block truncate">{{ timezone.label }}</span>
                  <span *ngIf="selectedTimezone.label == timezone.label"
                    [ngClass]="{'text-main': activeTimezone?.label !== timezone.label, 'text-white': activeTimezone?.label == timezone.label }"
                    class="absolute inset-y-0 right-0 flex items-center pr-4">
                    <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd" />
                    </svg>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-span-full mt-6 flex items-center justify-end gap-x-6">
            <button type="submit" class="inline-flex justify-center items-center gap-x-1">
              <span *ngIf="saving">
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                  viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                  </path>
                </svg>
              </span>
              <span *ngIf="!saving">Save</span>
              <span *ngIf="saving">Saving...</span>
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="mt-12">
      <div class="my-7">
        <p class="leading-7 text-slate-700 text-base">
          Easily proceed to <span class="font-bold">Event Industries</span> by clicking "Manage Industries." This button
          will
          guide you through updating industries of your event.
        </p>
      </div>
      <div class="flex">
        <div (click)="trackUpdate('Industries')" [routerLink]="['/event-editor', event.id, 'event-industries']" class="flex flex-col borded mt-4 md:w-1/2">
          <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
            <svg class="h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z"
                clip-rule="evenodd" />
            </svg>
            Event Industries
          </dt>
          <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
            <p class="flex-auto">Upload a custom list of industries to tailor your event's focus with Industries. Easily
              categorize and engage attendees based on their professional interests.</p>
            <p class="mt-6 md:text-end">
              <button type="button"
                class="rounded-md inline-flex items-center gap-x-1.5 bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Manage Industries
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="-mr-0.5 h-5 w-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                </svg>
              </button>
            </p>
          </dd>
        </div>
      </div>
    </div>
  </div>
</div>
