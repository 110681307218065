import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CustomEmailModel, EmailService } from 'src/app/shared/service/email.service';

@Component({
  selector: 'custom-email',
  templateUrl: './custom-email.component.html',
  styleUrl: './custom-email.component.scss',
  encapsulation: ViewEncapsulation.Emulated
})
export class CustomEmailComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  htmlContent: string = '';
  sending: boolean = false;


  isOpen = false;
  isOpen2 = false;

  constructor(
    private emailService: EmailService
  ) { }

  ngOnInit(): void {

  }

  onEditorChange(htmlContent: string) {
    this.htmlContent = htmlContent;
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  toggleOpen2() {
    this.isOpen2 = !this.isOpen2;
  }

  onSubmit() {
    this.sending = true;
    const customEmailModel: CustomEmailModel = {
      subject: this.form.value.subject,
      htmlContent: this.htmlContent
    };


    console.log(customEmailModel);

    this.emailService
      .sendCustomEmail(customEmailModel)
      .subscribe({
        next: _ => this.sending = false,
        error: _ => this.sending = false,
      });
  }
}
