import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Event } from 'src/app/shared/model/event/event.model';
import { Profile } from 'src/app/shared/model/user/profile.model';
import { AttendeesService } from 'src/app/shared/service/attendees.service';
import { EventService } from 'src/app/shared/service/event.service';

@Component({
  selector: 'attendee-details',
  templateUrl: './attendee-details.component.html',
  styleUrl: './attendee-details.component.scss',
  animations: [
    trigger('translateX', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('500ms ease-in-out', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('500ms ease-in-out', style({ transform: 'translateX(100%)' }))
      ])
    ]),
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' }))
      ])
    ]),
    trigger('AnimationTrigger0', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' }))
      ])
    ]),
  ]
})
export class AttendeeDetailsComponent implements OnChanges {
  @Input('attendee') attendee: Profile;
  displayOnly=['conf-reg-email', 'check-in'];
  loading: boolean = false;
  user;
  profilePicture: string;
  event: Event;
  currentTab: string = 'profile';

  constructor(
    private eventService: EventService,
    private attendeesService: AttendeesService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.event = this.eventService.selectedEvent.value;
    this.getProfilePhotoPath();
    this.user = this.attendee.user;
  }

  getProfilePhotoPath() {
    this.profilePicture = this.attendee.user?.profilePhoto?.name || '';
  }

  onTabChange(tab: string) {
    this.currentTab = tab;
  }
}
